import {Component, OnInit} from '@angular/core';

import {ApiService} from "../../../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {ItemListItem, ItemListMapper} from "../../../../shared/item-list/ItemListItem";
import {ListViewAction} from "../../../../shared/models/Actions";
import {ListActionEvent} from "../../../../shared/models/ListActionEvent";
import {ItemManager} from "../../../../shared/item-list/ItemManager";
import {FinishedJobDto} from "../../../../shared/entities/jobs/FinishedJobDto";
import {JobDistributedDeviceDto} from "../../../../shared/entities/jobs/JobDistributedDeviceDto";
import {PROGRAMMING_STATE} from "../../../../shared/lookup/programming.lookup";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

@Component({
    selector: 'app-finished-jobs-form',
    templateUrl: './finished-jobs-form.component.html',
    styleUrls: ['./finished-jobs-form.component.scss'],
    standalone: false
})
export class FinishedJobsFormComponent implements OnInit, ItemListMapper<FinishedJobDto> {
  itemManager: ItemManager<FinishedJobDto>;
  selectedItem?: FinishedJobDto;
  currentDistributedDevices?: JobDistributedDeviceDto[]
  isLoading: boolean = false;

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService,
    private formatter: FormatterService) {
    this.itemManager = new ItemManager<FinishedJobDto>(this, this.notification);
  }

  async ngOnInit() {
    this.isLoading = true;
    (await this.apiService.job.getAllFinishedJobs()).subscribe({
      next: value => this.itemManager.setItems(value),
      complete: () => this.isLoading = false
    });
  }

  mapToItemList(item: FinishedJobDto): ItemListItem<FinishedJobDto> {

    const name = this.translate.instant(
      "JOBS.ITEM_LIST.NAME",
      {id: item.id, name: item.shortName, uid: item.lockingDeviceUid})

    const creationDetails = this.translate.instant(
      "JOBS.ITEM_LIST.CREATED_DETAILS",
      {date: this.formatter.formatTimestamp(item.createdTimestamp, {mode: 'date'}), user: item.createdUsername})

    const finishedDetails = this.translate.instant(
      "JOBS.ITEM_LIST.FINISHED_DETAILS",
      {date: this.formatter.formatTimestamp(item.finishedTimestamp, {mode: 'date'}), user: item.createdUsername})

    return new ItemListItem(`${item.id}`, name, item)
      .addInfo(creationDetails)
      .addInfo(finishedDetails)
      .addAction(new ListViewAction())
      .setImage(`assets/ces/job/finished_${item.stateId == 2 ? 'success' : 'not_success'}.svg`)
  }

  async onListEvent(listActionEvent: ListActionEvent<FinishedJobDto>) {
    this.selectedItem = listActionEvent.item;
    this.currentDistributedDevices = (this.selectedItem.distributedDevices || []);
  }

  get programmingStateTranslationText():string {
    return `JOBS.STATE.${[...PROGRAMMING_STATE.filter(value => (this.selectedItem?.['stateId'] || '1') == `${value.id}`),PROGRAMMING_STATE[0]][0].value.toUpperCase()}`;
  }
}
