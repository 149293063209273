import {Component, Input} from '@angular/core';
import {ACCESS_TYPE} from "../../../../shared/lookup/access.lookup";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";
import {LICENSE} from "../../../../shared/lookup/license.lookup";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-access-profile-form',
    templateUrl: './access-profile-form.component.html',
    styleUrls: ['./access-profile-form.component.scss'],
    standalone: false
})
export class AccessProfileFormComponent {
  accessProfileItem?: ChangeDetectorValue;
  validatorMaxLength32: number = 32;
  validatorMaxLength64: number = 64;

  @Input() set accessProfile(accessProfile: ChangeDetectorValue) {
    if (accessProfile == null || accessProfile.value == undefined) return;

    this.accessProfileItem = accessProfile;
  }

  constructor(private translate: TranslateService) {
  }

  // Access
  @Input() accessReadonly: boolean = false;
  @Input() licenseTypeId: number = 1;
  @Input() licenseNotValid: boolean = false;

  get accessReadonlyGetter(): boolean {
    return this.accessReadonly || this.licenseNotValid;
  }

  protected readonly ACCESS_TYPE = ACCESS_TYPE;
}
