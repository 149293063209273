import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {NgbModal, NgbModalRef, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {ImageCroppedEvent, ImageCropperComponent} from "ngx-image-cropper";
import {ROLE} from "../../../../../shared/lookup/role.lookup";
import {COUNTRY} from "../../../../../shared/lookup/country.lookup";
import {LookupMapping} from "../../../../../shared/lookup/lookup";
import {ToastService} from "../../../../../shared/notification/toast/toast.service";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import 'hammerjs';
import {SafeUrl} from "@angular/platform-browser";
import {BlobBase64} from "../../../../../shared/util/blobBase64";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {parseInt} from "lodash";
import {UserWithImageCdm} from "../../../../../shared/util/change-detector/models/user-with-image.cdm";
import {take} from "rxjs";
import {UserRoleType} from "../../../../../core/enums/userRoleType";
import {UserMailVerificationDto} from "../../../../../shared/entities/user/UserMailVerificationDto";
import {TranslateService} from "@ngx-translate/core";
import {EmailStatus} from "../../../../../core/enums/emailStatus.enum";

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    standalone: false
})
export class UserFormComponent {

  constructor(
    private modalService: NgbModal,
    private notification: ToastService,
    private translate: TranslateService,
    private apiService: ApiService
  ) {
  }

  croppedImage: SafeUrl = '';

  // Access
  @Input() canChangeUserRole = false
  @Input() canChangeUserAccessSettings = false
  @Input() licenseExpired = false

  // Events
  @Output() onChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() verificationTriggered: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Data
  userValue: ChangeDetectorValue = new ChangeDetectorValue(undefined);
  userVerification: UserMailVerificationDto = new UserMailVerificationDto(EmailStatus.Expired || 'expired');

  // User image
  userImageData?: string
  userImageBlob?: Blob;
  userImageChanged = false
  private _hasUserImage: boolean = false
  imageTooLarge: boolean = false

  isSelectedUserLoggedIn = false;
  isNewUser: boolean = false
  wasOfflineUser: boolean = false
  verificationLoading: boolean = true;
  tooltipContent: string = '';

  imageModal?: NgbModalRef
  imageFile?: File

  validatorMaxLength10: number = 10;
  validatorMaxLength32: number = 32;
  validatorMaxLength64: number = 64;

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  @ViewChild('tooltip', {static: true}) tooltip!: NgbTooltip;

  @Input() set user(user: UserWithImageCdm) {
    this.verificationLoading = true;
    this.lastMailIsFree = true;
    this.usernameIsTaken = false;
    if (user == null) return;

    this.isNewUser = (!user.uuid || user.uuid == '');
    this.wasOfflineUser = (user.mail || '').length == 0 || (user.username || '').length == 0;
    if (!this.isNewUser) {
      this.apiService.user.getMailVerification(user.uuid).then(value =>
        value.pipe(take(1)).subscribe(dto => {
          this.userVerification = dto;
          this.verificationLoading = false;
        })
      );
    }
    this.isSelectedUserLoggedIn = this.apiService.user.session!.userUUID == user.uuid;
    this.userValue = new ChangeDetectorValue(user, () => {
      this.onChanges.emit()
    }, user.validators());

    this._hasUserImage = user.imageAvailable || false;
    this.userImageData = user.userImage;
    this.userImageChanged = false;
  }

  reset() {
    this.userImageData = undefined;
    this.userImageChanged = false;
    this.userValue.reset();
  }

  async onSelectImage(modal: any, event: any) {
    this.imageTooLarge = false
    this.imageFile = event.target?.files?.[0]

    // reset selector to ensure same file is selectable again
    const selectorElement = document.getElementById('userImageSelector') as HTMLInputElement
    selectorElement.files = null
    selectorElement.value = ''

    if (this.imageFile != null) {
      this.imageModal = this.modalService.open(modal, {centered: true, scrollable: false})
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    const maxFileSizeMB = 2; // Maximum file size in megabytes
    this.userImageBlob = event.blob == null ? undefined : event.blob;
    this.imageTooLarge = (this.userImageBlob?.size || 0) > maxFileSizeMB * 1024 * 1024;
  }

  async onSaveImage() {
    const base64Image: string = await BlobBase64.BLOB_TO_BASE_64(this.userImageBlob!);

    if (this.userImageBlob == undefined) {
      this.imageModal?.close()
      this.notification.showError('NOTIFICATION.TOAST.ERROR.DEFAULT');
      return;
    }

    // update user image data
    this.userImageData = base64Image!.substring(base64Image!.indexOf(';base64,') + 8) // get image data only
    this.userImageChanged = true

    this.onChanges.next(true);

    this._hasUserImage = true;
    this.imageModal?.close();
  }

  onClearUserImage() {
    this._hasUserImage = false
    this.userValue.value.imageAvailable = false;
    this.userImageChanged = true
    this.onChanges.next(true);
  }

  lastMailIsFree: boolean = true;
  usernameIsTaken: boolean = false;

  async userNameSuggestion(fromFieldUsername: boolean) {
    if (!this.userValue.isSpecificGroupValid('mail')) {
      return;
    }

    if (!this.userValue.hasSpecificChanges('mail')) {
      this.lastMailIsFree = true;
    }
    if (!this.userValue.hasSpecificChanges('username')) {
      this.usernameIsTaken = false;
    }

    let updateUserNameField: boolean;
    let updateUsernameIsTaken: boolean;
    let updateLastMailIsFree: boolean;

    if (fromFieldUsername) {
      updateUserNameField = false;
      updateUsernameIsTaken = this.isNewUser ||
        this.userValue.value.username.toLowerCase() != this.userValue.initialValue.username.toLowerCase();
      updateLastMailIsFree = false;
    } else {
      updateUserNameField = this.isNewUser ||
        this.userValue.value.username.toLowerCase() != this.userValue.initialValue.username.toLowerCase();
      updateUsernameIsTaken = false;
      updateLastMailIsFree = this.isNewUser ||
        this.userValue.value.mail.toLowerCase() != this.userValue.initialValue.mail.toLowerCase();
    }

    const requestParameter: string = fromFieldUsername ? this.userValue.value.username : this.userValue.value.mail;

    this.apiService.user.userNameSuggestion(requestParameter).then(value => {
      value.pipe(take(1)).subscribe(obj => {
        if (updateUsernameIsTaken) {
          this.usernameIsTaken = this.userValue.value.username.toLowerCase() != obj.name.toLowerCase();
        }
        if (updateUserNameField) {
          this.userValue.value.username = obj.name;
        }
        if (updateLastMailIsFree) {
          this.lastMailIsFree = obj.mailIsFree;
        }
      });
    });
  }

  get isOnlineUser() {
    return ((this.userValue.value.username || '').length != 0 || (this.userValue.value.mail || '').length != 0);
  }

  get user(): UserWithImageCdm {
    let user: UserWithImageCdm = new UserWithImageCdm();
    user.values = this.userValue.value
    if ((this.userValue.value.mail || '').length == 0) {
      user.mail = '';
      user.username = '';
    } else {
      user.mail = this.userValue.hasSpecificChanges('mail') ? user.mail : this.userValue.initialValue.mail;
    }
    user.countryId = typeof this.userValue.value.countryId === 'string' ? parseInt(`${user.countryId}`) : user.countryId;
    user.emailStatus = this.userVerification.emailStatus;
    if (!this.isOnlineUser) {
      user.roleId = UserRoleType.USER || 4;
      user.emailStatus = EmailStatus.Unknown || 'unknown';
    }
    return user;
  }

  getUserImageData(): string {
    return this.userImageData || '';
  }

  getUserImage(): Blob | undefined {
    return this.userImageBlob;
  }

  requireUserImageUpdate(): boolean {
    return this.userImageChanged || this._hasUserImage != (this.userValue.value.imageAvailable || false);
  }

  get userRoleTranslationText(): string {
    const role: LookupMapping[] = ROLE.filter(value => `${this.userValue.value.roleId}` == `${value.id}`);
    return `USERS.USERS.ROLE.${role.length > 0 ? role[0].value.toUpperCase() : 'UNKNOWN'}`;
  }

  get userCountryTranslationText(): string {
    switch (parseInt(this.userValue.value.countryId || 0)) {
      case 1:
        return 'COUNTRY.GERMANY';
      case 2:
        return 'COUNTRY.NETHERLANDS';
      default:
        return `USERS.USERS.FIELD.COUNTRY.EMPTY`;
    }
  }

  protected readonly ROLE = ROLE.filter(role => role.id != 1);
  protected readonly COUNTRY = COUNTRY;

  get userIsAllowedToResetPassword(): boolean {
    return this.apiService.user.isAdminOrHigher;
  }

  get isMailVerifiedOrUnknown(): boolean {
    return this.userVerification.emailStatus == (EmailStatus.Verified || 'verified') ||
      this.userVerification.emailStatus == (EmailStatus.Unknown || 'unknown');
  }

  async triggerEMailVerification() {
    this.verificationLoading = true;
    this.apiService.user.triggerMailVerification(this.userValue.value.uuid).finally(() => {
      if (!this.isNewUser) {
        this.apiService.user.getMailVerification(this.userValue.value.uuid).then(value =>
          value.pipe(take(1)).subscribe(dto => {
            this.userVerification = dto;
            this.verificationLoading = false;
            this.verificationTriggered.emit(true);
          })
        );
      }
    });
  }

  async resetPassword() {
    if (this.userVerification.emailStatus == (EmailStatus.Verified || 'verified')) {
      if (await this.apiService.auth.resetPassword(
        this.apiService.auth.system!, this.userValue.value.username)) {
        this.notification.showSuccess();
      }
    } else if (this.userVerification.emailStatus != (EmailStatus.Unknown || 'unknown')) {
      this.notification.showWarning('USERS.USERS.FIELD.MAIL.VERIFICATION.NEEDED.TEXT');
    }
  }

  get hasUserImage(): boolean {
    return this._hasUserImage;
  }

  get defaultImage(): string {
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null && themeDesign.includes('black')) {
      return "assets/ces/user/user-black.svg";
    }
    return "assets/ces/user/user.svg";
  }

  get detailsValid(): boolean {
    return this.userValue != undefined && this.userValue.value != undefined &&
      this.userValue.isSpecificGroupValid('dateOfBirth', 'personnelNo', 'department', 'activity',
        'area', 'phoneOffice', 'phonePrivate', 'phoneMobile');
  }

  protected readonly alert = alert;
}
