import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {SelectListComponent} from "../../../../../shared/select-list/select-list.component";
import {SelectListItem} from "../../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {defaultImage} from "../../../user-users/user-users.component";
import {UserDto} from "../../../../../shared/entities/user/UserDto";
import {UserGroupAssignmentDto} from "../../../../../shared/entities/user-group/UserGroupAssignmentDto";
import {ROLE} from "../../../../../shared/lookup/role.lookup";
import {LookupMapping} from "../../../../../shared/lookup/lookup";

@Component({
    selector: 'app-user-group-assignment-form',
    templateUrl: './user-group-assignment-form.component.html',
    styleUrls: ['./user-group-assignment-form.component.scss'],
    standalone: false
})
export class UserGroupAssignmentFormComponent implements OnInit {


  @ViewChild(SelectListComponent) selectListComponent!: SelectListComponent

  items: SelectListItem[] = [];
  selectedItems = new Set<string>()

  @Input() readonly = false
  @Input() set users(userDtos: UserDto[]) {
    this.setItems(userDtos);
  }

  @Input() set assignedUsers(assignedUsers: UserGroupAssignmentDto[] | undefined) {
    this.selectedItems = new Set<string>;
    this.setSelectedItems(assignedUsers);
  }

  // Changes
  _changed = false
  @Output() onChanges = new EventEmitter<void>()

  private async setItems(userDtos: UserDto[] | undefined) {
    const promises = (userDtos || []).map(async (entry: UserDto) => {
      const listItem = new SelectListItem(entry.uuid, `${entry.firstName} ${entry.lastName}`);
      const role: LookupMapping[] = ROLE.filter(value => value.id == entry.roleId);
      if (entry.blocked) {
        listItem.addBadge('mdi mdi-shield-alert-outline','rounded-pill badge-soft-primary blocked-user-badge',
          this.translate.instant('USERS.USERS.FIELD.BLOCKED.BLOCK.DETAIL'));
      }
      listItem.addInfo(this.translate.instant(`USERS.USERS.ROLE.${role.length > 0 ? role[0].value.toUpperCase(): 'UNKNOWN'}`));
      listItem.setImage(defaultImage);

      // load user image
      if (entry.imageAvailable) {
        const base64Image = await this.apiService.user.getUserImage(entry.uuid);
        if (base64Image != null) {
          listItem.setImage(`data:image/*;base64,${base64Image}`);
        }
      }
      return listItem;
    });

    this.items = await Promise.all(promises);
  }

  private setSelectedItems(assignedUsers: UserGroupAssignmentDto[] | undefined) {
    if(assignedUsers == null || assignedUsers.length == 0) {
      return;
    }
    this.selectedItems = assignedUsers.reduce<Set<string>>((set, entry) => {
      set.add(entry.userUuid);
      return set;
    }, new Set<string>());
  }

  constructor(
    private apiService: ApiService,
    private translate: TranslateService
  ) { }

  ngOnInit() { }


  onSelectionChanges(changed: boolean) {
    this._changed = changed
    this.onChanges.emit()
  }

  get changed(): boolean {
    return this._changed
  }

  getSelectedUsers(): string[] {
    return Array.from(this.selectListComponent.selectedItems);
  }

  reset() {
    this.items = []
    this.selectedItems.clear()
    this.selectListComponent.resetChangeDetection()
  }
}
