import { Component } from '@angular/core';

@Component({
    selector: 'app-locking-media-rebinding',
    templateUrl: './locking-media-rebinding.component.html',
    styleUrl: './locking-media-rebinding.component.scss',
    standalone: false
})
export class LockingMediaRebindingComponent {

}
