<form>
  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-12">
      <div class="card shadow-none">
        <div class="card-body">

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly"
                   type="text"
                   class="form-control"
                   data-testid="locking-group-name"
                   id="name"
                   name="name"
                   [maxLength]="lockingDeviceDetails.value.nameMaxLength"
                   [(ngModel)]="lockingDeviceDetails.value.name"
                   [ngClass]="{ 'is-invalid': lockingDeviceDetails | hasChangeErrors:'name', 'pending-changes': !(lockingDeviceDetails | hasChangeErrors:'name') && lockingDeviceDetails.hasSpecificChanges('name')}">
            <label for="name">{{ "LOCKING_DEVICES.GROUPS.FIELD.NAME.TEXT" | translate }}*</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
            <div *ngIf="lockingDeviceDetails | hasChangeErrors:'name'" class="invalid-feedback">
              <div *ngIf="lockingDeviceDetails | hasChangeErrors:'name':'Required'">
                {{ "LOCKING_DEVICES.GROUPS.FIELD.NAME.REQUIRED" | translate }}
              </div>
              <div *ngIf="lockingDeviceDetails | hasChangeErrors:'name':'Max'">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_DEVICES.GROUPS.FIELD.NAME.TEXT" | translate) : ('' + lockingDeviceDetails.value.nameMaxLength) }}
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly"
                   type="text"
                   class="form-control"
                   data-testid="locking-group-description"
                   id="description"
                   name="description"
                   [maxLength]="lockingDeviceDetails.value.descriptionMaxLength"
                   [(ngModel)]="lockingDeviceDetails.value.description"
                   [ngClass]="{ 'is-invalid': lockingDeviceDetails | hasChangeErrors:'description', 'pending-changes': !(lockingDeviceDetails | hasChangeErrors:'description') && lockingDeviceDetails.hasSpecificChanges('description')}">
            <label for="description">{{ "LOCKING_DEVICES.GROUPS.FIELD.DESCRIPTION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="description"></i>
            </div>
            <div *ngIf="lockingDeviceDetails | hasChangeErrors:'description'" class="invalid-feedback">
              <div *ngIf="lockingDeviceDetails | hasChangeErrors:'description'">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_DEVICES.GROUPS.FIELD.DESCRIPTION.TEXT" | translate) : ('' + lockingDeviceDetails.value.descriptionMaxLength) }}
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input disabled
                   id="groupTypeId"
                   class="form-control"
                   data-testid="locking-group-groupTypeId"
                   [value]="deviceGroupTypeTranslationText | translate">
            <label for="groupTypeId">{{ "LOCKING_DEVICES.GROUPS.FIELD.GROUP_TYPE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="technical"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form><!-- end form -->
