<div class="sticky-header">
  <app-pagetitle id="pagetitle" title="{{'MENU.LOCKING_DEVICES' | translate}}" (onSearch)="onSearch($event)"
                 [disableButtons]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                 [searchEntries]="searchEntries" [itemSelected]="minimizePagetitle">
    <div *ngIf="canAddGroup && isParamsGroupAllowed" ngbDropdown class="d-flex">
      <button data-testid="addGroupParamsDropdown" id="addGroupParamsDropdown"
              ngbDropdownToggle type="button" class="btn btn-outline-primary">
        {{ "BUTTON.ADD" | translate }}
        <i class="mdi mdi-chevron-down"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="addGroupDropdown" data-testid="addGroupDropdown-multi">
        <button *ngFor="let groupType of DEVICE_GROUP" ngbDropdownItem
                type="button" class="btn btn-outline-primary" (click)="onAddGroup(groupType.id)">
          {{ ('LOCKING_DEVICES.GROUP_TYPE.' + groupType.value.toUpperCase()) | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="canAddGroup && !isParamsGroupAllowed" ngbDropdown class="d-flex">
      <button data-testid="addGroupDropdown" id="addGroupDropdown"
              type="button" class="btn btn-outline-primary" (click)="onAddGroup(GROUP_TYPES.ACCESS)">
        {{ "BUTTON.ADD" | translate }}
      </button>
    </div>
    <div *ngIf="canAddOrRemoveDevices" ngbDropdown class="d-flex">
      <button data-testid="addDevice" id="addDevice" [disabled]="!ioBridgeConnected"
              type="button" class="btn btn-outline-primary" (click)="onAddDevice()">
        {{ "BUTTON.ADD" | translate }}
      </button>
    </div>
  </app-pagetitle>
</div>

<div class="scrollable-content">
  <div class="card card-body mb-2">
  <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified bg-light" [(activeId)]="selectedNavTab"
      (navChange)="onNavigation($event)">
    <li *ngIf="loaded" ngbNavItem="devices">
      <a ngbNavLink><span class="black-align-text">{{ "LOCKING_DEVICES.TAB.LOCKING_DEVICES" | translate }}</span></a>
      <ng-template ngbNavContent>
        <app-locking-device-devices [onSearch]="searchQuery" (onUpdate)="onUpdate.next()"
                                    [readonlyLicenseInvalid]="licenseTypeId > 1 && !licenseIsValidForBusiness">
        </app-locking-device-devices>
      </ng-template>
    </li>
    <li *ngIf="licenseTypeId > 1 && loaded" ngbNavItem="groups">
      <a ngbNavLink><span class="black-align-text">{{ "LOCKING_DEVICES.TAB.LOCKING_DEVICE_GROUPS" | translate }}</span></a>
      <ng-template ngbNavContent>
        <app-locking-device-groups [onSearch]="searchQuery" (onUpdate)="onUpdate.next()"
                                   [readonlyLicenseInvalid]="licenseTypeId > 1 && !licenseIsValidForBusiness">
        </app-locking-device-groups>
      </ng-template>
    </li>
  </ul>
</div>

  <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-0"></div>
</div>
