<div class="sticky-header">
  <app-pagetitle title="{{'MENU.SYSTEM' | translate}}" [disableSearch]="true" [shiftRight]="true"
                 (onPagetitleAction)="onSubmit()" [pagetitleActions]="[pageTitleSaveAction]">
  </app-pagetitle>
</div>
<div class="scrollable-content">

  <div class="card card-body mb-2">
    <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified bg-light"
        [(activeId)]="selectedNavTab">
      <li ngbNavItem="overview">
        <a ngbNavLink><span class="black-align-text">{{ "SYSTEM.TAB.OVERVIEW" | translate }}</span></a>
        <ng-template ngbNavContent>
          <app-system-overview [accessReadonly]="!userIsAllowed" [system]="itemChangeValue" [timezone]="itemChangeValue.value.timezoneId"></app-system-overview>
        </ng-template>
      </li>
      <li *ngIf="userIsAllowed" ngbNavItem="settings">
        <a ngbNavLink><span class="black-align-text">{{ "SYSTEM.TAB.SETTINGS" | translate }}</span></a>
        <ng-template ngbNavContent>
          <app-system-settings [system]="itemChangeValue"></app-system-settings>
        </ng-template>
      </li>
    </ul>
  </div>

  <app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
                 [(itemSelected)]="itemSelected" [hideEditHeaders]="true">
    <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-0"></div>
  </app-item-list>
</div>
