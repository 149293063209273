import {ChangeDetectorModel, ChangeDetectorValue} from "../ChangeDetectorValue";
import {LockingMediaDto} from "../../../entities/LockingMediaDto";
import {EventEmitter} from "@angular/core";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";

export class LockingMediaCdm implements ChangeDetectorModel, LockingMediaDto {
  viewablePasswordFields: string[] = [];

  private _articleNumber: string | undefined;
  private _assignedUsers: string[] | undefined;
  private _dateHandout: number | undefined | { year: number; month: number; day: number; };
  private _dateReturn: number | undefined | { year: number; month: number; day: number; };
  private _displayUid: string = '';
  private _implementationId: number = 0;
  private _locked: boolean = false;
  private _mechanicalId: string | undefined;
  private _mediaNumber: number = 0;
  private _stateId: number = 0;
  private _typeId: number = 0;
  private _uuid: string = '';
  valueChange: EventEmitter<any>;
  private formatter: FormatterService;

  constructor() {
    this.valueChange = new EventEmitter();
    this.formatter = new FormatterService();
  }

  validators(): Map<string, (input: any) => boolean> {
    return ChangeDetectorValue.mergeValidatorMaps(
      ChangeDetectorValue.createNumberValidatorMinMaxRequired('stateId', 1, 6),
      new Map<string, (input: any) => boolean>()
        .set('mechanicalIdMax',(input: any) => {
            return (input._mechanicalId || '').length < 32;
        }).set('dateReturnValid', (input: any) => {
            if (input._dateHandout == undefined || input._dateReturn == undefined) {
                return true;
            }
            return input.dateReturn >= input.dateHandout;
        }).set('displayUid', (input: any) => {
            return input._mediaNumber < 100000 ? (input._displayUid < 100000 && input._displayUid > 0) : true
        })
    );
  }

  set values(values: any) {
    if (values && typeof values == 'object') {
      for (let key in values) {
        const newVal = this.hasProp(values, key) ? values[key] : this.hasProp(this, `_${key}`) ? values[`_${key}`] : undefined;
        //@ts-ignore
        this.hasProp(this, key) ? this[key] = newVal : this.hasProp(this, `_${key}`) ? this[`_${key}`] = newVal : () => { };
      }
    }
  }

  get toLockingMediaDto(): LockingMediaDto {
    return new LockingMediaDto(
      this.uuid,
      this.displayUid,
      this.articleNumber,
      this.mechanicalId,
      this.typeId,
      this.implementationId,
      this.stateId,
      this.locked,
      this.dateHandout,
      this.dateReturn,
      this.assignedUsers,
      this.mediaNumber
    );
  }

  private hasProp(thisArg: any, args_0: string): boolean {
    return Object.prototype.hasOwnProperty.call(thisArg, args_0);
  }

  get articleNumber(): string | undefined {
    return this._articleNumber;
  }

  set articleNumber(value: string | undefined) {
    this._articleNumber = value;
    this.valueChange.emit(true);
  }

  get assignedUsers(): string[] | undefined {
    return this._assignedUsers;
  }

  set assignedUsers(value: string[] | undefined) {
    this._assignedUsers = value;
    this.valueChange.emit(true);
  }

  get dateHandout(): number | undefined {
    if (this._dateHandout != undefined && typeof this._dateHandout !== 'number') {
      this._dateHandout = new FormatterService().formatAsUTCFromNgbDatePicker(this._dateHandout).getTime() / 1000;
    }
    return this._dateHandout;
  }

  set dateHandout(value: number | undefined | { year: number; month: number; day: number; }) {
    if (value != undefined && typeof value !== 'number') {
      value = new FormatterService().formatAsUTCFromNgbDatePicker(value).getTime() / 1000;
    }
    this._dateHandout = value;
    this.valueChange.emit(true);
  }

  get dateReturn(): number | undefined {
    if (this._dateReturn != undefined && typeof this._dateReturn !== 'number') {
      this._dateReturn = new FormatterService().formatAsUTCFromNgbDatePicker(this._dateReturn).getTime() / 1000;
    }
    return this._dateReturn;
  }

  set dateReturn(value: number | undefined | { year: number; month: number; day: number; }) {
    if (value != undefined && typeof value !== 'number') {
      value = new FormatterService().formatAsUTCFromNgbDatePicker(value).getTime() / 1000;
    }
    this._dateReturn = value;
    this.valueChange.emit(true);
  }

  get displayUid(): string {
    return this._displayUid;
  }

  set displayUid(value: string) {
    this._displayUid = value;
    this.valueChange.emit(true);
  }

  get implementationId(): number {
    return this._implementationId;
  }

  set implementationId(value: number) {
    this._implementationId = value;
    this.valueChange.emit(true);
  }

  get locked(): boolean {
    return this._locked;
  }

  set locked(value: boolean) {
    this._locked = value;
    this.valueChange.emit(true);
  }

  get mechanicalId(): string | undefined {
    return this._mechanicalId;
  }

  set mechanicalId(value: string | undefined) {
    this._mechanicalId = value;
    this.valueChange.emit(true);
  }

  get mediaNumber(): number {
    return this._mediaNumber;
  }

  set mediaNumber(value: number) {
    this._mediaNumber = value;
    this.valueChange.emit(true);
  }

  get stateId(): number {
    return this._stateId;
  }

  set stateId(value: number) {
    this._stateId = value;
    this.valueChange.emit(true);
  }

  get typeId(): number {
    return this._typeId;
  }

  set typeId(value: number) {
    this._typeId = value;
    this.valueChange.emit(true);
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
    this.valueChange.emit(true);
  }


}
