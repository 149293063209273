import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, take} from "rxjs";
import {environment} from "../../../../environments/environment";
import {Logger} from "../../../shared/util/logger";

@Injectable({
  providedIn: 'root'
})
export class UnleashService {

  private readonly default_config_url: 'settings/features.json' = 'settings/features.json';

  defaultValues: Map<string, boolean> = new Map()
  featureFlags: Map<string, BehaviorSubject<boolean>> = new Map();

  private refreshTimeout: number = 30 * 1000;
  private callTimeout: number = 5 * 1000;

  private running: boolean = false;
  private nextCall: number = 0;

  constructor(
    private http: HttpClient,
  ) {
    Object.entries(environment.featureFlags).map(f => {
      this.defaultValues.set(f[1].name, f[1].defaultValue)
      this.featureFlags.set(f[1].name, new BehaviorSubject(f[1].defaultValue))
    })
    this.getDefaults(this.default_config_url);
  }

  public async featureFlagsRecursiveUpdater(recursion: boolean) {
    if (this.running || this.nextCall > Date.now()) {
      Logger.info('Unleash needs to wait');
    } else {
      this.getEnabled();
    }
    if (recursion) {
      setTimeout((): Promise<void> => this.featureFlagsRecursiveUpdater(true), this.callTimeout);
    }
  }

  private async getEnabled() {
    this.running = true;
    this.nextCall = Date.now() + this.refreshTimeout;
    this.http.get<UnleashResponse>(environment.unleashUrl).subscribe({
      next: (data: UnleashResponse) => {
        let response: SimpleFF[] = data.toggles
          .filter(t => t.enabled && t.name.startsWith(environment.state))
          .map(t => new SimpleFF(t.name.replace(`${environment.state}_`, ''), t.enabled));

        Object.values(environment.featureFlags)
          .filter(f => response.map(r => r.name).includes(f.name))
          .map(f => {
            return {
              name: f.name,
              enabled: f.defaultValue,
              newValue: response.filter(r => r.name == f.name)[0].defaultValue
            };
          })
          .filter(f => f.newValue != f.enabled)
          .forEach(f => {
            if (this.featureFlags.get(f.name) != undefined) {
              this.featureFlags.get(f.name)!.next(f.newValue);
            } else {
              this.featureFlags.set(f.name, new BehaviorSubject(f.newValue));
            }
        });
      },
      error: (e) => {
        this.defaultValues.forEach((f, key) => {
          if (this.featureFlags.get(key)?.value != f) {
            this.featureFlags.get(key)?.next(f)
          }
        });
      },
      complete: () => {
        this.running = false;
      }
    });
  }

  getDefaults(url: string) {
    this.running = true;
    this.http.get<SimpleFF[]>(url)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          data.forEach(f => {
            this.defaultValues.set(f.name, f.defaultValue)
            this.featureFlags.set(f.name, new BehaviorSubject(f.defaultValue))
          });
        },
        error: (err) => {
          Logger.error(`Unleash error: ${err}`);
        },
        complete: () => {
          this.running = false;
        }
      });
  }
}

export class SimpleFF {
  name: string;
  defaultValue: boolean;

  public constructor(name: string, enabled: boolean) {
    this.name = name;
    this.defaultValue = enabled;
  }
}

export interface UnleashResponse {
  toggles: { name: string, enabled: boolean, variant: Object, impressionData: boolean }[]
}
