<app-commissioning
    [commissionManager]="commissionManager"
    [globalBindingErrorProcess]="globalBindingErrorProcess"
    [isLoading]="isLoading"
    [providePin]="providePin"
    [provideSN]="provideSN"
    [deviceName]="deviceName"
    [processAndPollingRunning]="processAndPollingRunning"
    [headerText]="headerText"
    [buttonText]="buttonText"
    [addRemoveButton]="addRemoveButton"
    [pinInputEnabled]="pinInputEnabled">


    <!-- MEDIA NUMBER INPUT -->
    <div class="dtw-input">
        <div class="form-floating form-floating-custom flex-grow-1">
            <input type="text" class="form-control" id="sn-id"
                    [(ngModel)]="provideSN"
                    [disabled]="!serialNumberInputEnabled || commissionManager.errorIsPresent">
            <label for="sn-id"
                    class="text-truncate">{{ "PROGRAMMING.MODAL.INPUT_FIELDS.MEDIA_NUMBER" | translate }}</label>
            <div class="form-floating-icon">
                <i icon="id"></i>
            </div>
            <div *ngIf="!snValid" class="invalid-feedback">
                <div >{{ "LOCKING_MEDIA.FIELD.DISPLAY_UID.INVALID" | translate }}</div>
            </div>
        </div>
    </div>
    <!-- PIN INPUT -->
    <!-- <div class="dtw-input">
        <div class="form-floating form-floating-custom flex-grow-1">
            <input type="text" class="form-control" id="pin-id"
                    [(ngModel)]="providePin"
                    [disabled]="processAndPollingRunning || isLoading || errorIsPresent">
            <label for="pin-id" class="text-truncate">{{ "PROGRAMMING.MODAL.INPUT_FIELDS.PAIRING_ID" | translate }}</label>
            <div class="form-floating-icon">
                <i icon="io-pin"></i>
            </div>
        </div>
    </div> -->
</app-commissioning>
