import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import {ITimeModelDynamicForm} from "../ITimeModelDynamicForm";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {ChangeDetectorForm} from "../../../../shared/util/change-detector/ChangeDetectorForm";
import {FormValidator} from "../../../../shared/util/FormValidator";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
    selector: 'app-time-model-interval-form',
    templateUrl: './time-model-interval-form.component.html',
    styleUrls: ['./time-model-interval-form.component.scss'],
    standalone: false
})
export class TimeModelIntervalFormComponent implements OnInit, ITimeModelDynamicForm {
  private _startDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9}, () => { this.onChanges() });
  private _endDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9}, () => { this.onChanges() });

  accessReadonly: boolean = false
  readonly intervalEnabled = true;
  readonly customFormEnabled = true;

  get dynamicForm(): AbstractControl | boolean | null {
    // is valid
    return !this.invalid;
  }

  constructor(private formatter: FormatterService) {
  }

  ngOnInit(): void {
  }


  initialize(timeModel: TimeModelDto, accessReadonly: boolean): void {
    this.accessReadonly = accessReadonly;
    if(timeModel == null) return;

    const date: Date = new Date();
    this.startDate = this.formatter.formatToNgbDatePicker(date);
    date.setDate(date.getDate() + 1);
    this.endDate = this.formatter.formatToNgbDatePicker(date);

    if (timeModel.startDate != undefined && timeModel.startDate != 0) {
      this.startDate = this.formatter.formatToNgbDatePicker(timeModel.startDate);
    }
    if (timeModel.endValue != undefined && timeModel.endValue != 0) {
      this.endDate = this.formatter.formatToNgbDatePicker(timeModel.endValue, true);
    }

    this._startDate.reset();
    this._endDate.reset();
  }

  getTimeModel(): TimeModelDto {
    let timeModel: TimeModelDto = TimeModelDto.emptyTimeModelDto();
    timeModel.startDate = this.formatter.formatAsUTCFromNgbDatePicker(this.startDate).getTime() / 1000;
    timeModel.endTypeId = 2;
    timeModel.endValue = this.formatter.formatAsUTCFromNgbDatePicker(this.endDate).getTime() / 1000;
    return timeModel;
  }

  get invalid(): boolean {
    return this.formatter.formatAsUTCFromNgbDatePicker(this.startDate).getTime() >=
      this.formatter.formatAsUTCFromNgbDatePicker(this.endDate).getTime();
  }

  onChanges(): void {
  }

  get changed(): boolean {
    return this._startDate.hasChanges || this._endDate.hasChanges;
  }


  get endDateChanged(): boolean {
    return this._endDate.hasChanges;
  }

  get startDateChanged(): boolean {
    return this._startDate.hasChanges;
  }

  get startDate(): { year: number; month: number; day: number } {
    return this._startDate.value;
  }

  set startDate(value: { year: number; month: number; day: number }) {
    if (this.accessReadonly) {
      return;
    }
    this._startDate.value = value;
  }

  get endDate(): { year: number; month: number; day: number } {
    return this._endDate.value;
  }

  set endDate(value: { year: number; month: number; day: number }) {
    if (this.accessReadonly) {
      return;
    }
    this._endDate.value = value;
  }
}
