@if (userValue.value) {
  <div class="container">
    <form>
      <div class="row row-cols-lg-6 g-4">

        <div class="col-lg-6">
          <div class="card h-100 shadow-none">
            <h6 class="card-header">{{ "USERS.USERS.SECTION.ACCOUNT" | translate }}</h6>
            <div class="card-body">


              <div class="d-flex flex-column mb-3">
                <div class="d-flex gap-3">

                  <div id="user-name" class="d-flex flex-column flex-grow-1">
                    <div class="form-floating form-floating-custom mb-3">
                      <input [readonly]="licenseExpired"
                             type="text"
                             class="form-control"
                             id="firstName"
                             name="firstName"
                             [(ngModel)]="userValue.value.firstName"
                             [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('firstName'), 'pending-changes': userValue.hasSpecificChanges('firstName')}"
                             placeholder="-"
                             [maxLength]="validatorMaxLength64">
                      <label for="firstName">{{ "USERS.USERS.FIELD.FIRST_NAME.TEXT" | translate }}*</label>
                      <div class="form-floating-icon">
                        <i icon="real-name"></i>
                      </div>
                      <div class="d-flex">
                        @if (!userValue.isSpecificGroupValid('firstName')) {
                          <div class="invalid-feedback d-block">
                            <div>
                              @if (!userValue.isSpecificValid('firstName')) {
                                {{ "USERS.USERS.FIELD.FIRST_NAME.REQUIRED" | translate }}
                              } @else if (!userValue.isSpecificValid('firstNameMax')) {
                                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.FIRST_NAME.TEXT" | translate) : ('' + validatorMaxLength64) }}
                              } @else if (!userValue.isSpecificValid('firstNameRequired')) {
                                {{ "USERS.USERS.FIELD.FIRST_NAME.REQUIRED" | translate }}
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div class="form-floating form-floating-custom">
                      <input [readonly]="licenseExpired"
                             type="text"
                             class="form-control"
                             id="lastName"
                             name="lastName"
                             [(ngModel)]="userValue.value.lastName"
                             [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('lastName'), 'pending-changes': userValue.hasSpecificChanges('lastName')}"
                             placeholder="-"
                             [maxLength]="validatorMaxLength64">
                      <label for="lastName">{{ "USERS.USERS.FIELD.LAST_NAME.TEXT" | translate }}*</label>
                      <div class="form-floating-icon">
                        <i icon="real-name"></i>
                      </div>
                      <div class="d-flex">
                        @if (!userValue.isSpecificGroupValid('lastName')) {
                          <div class="invalid-feedback d-block">
                            <div>
                              @if (!userValue.isSpecificValid('lastName')) {
                                {{ "USERS.USERS.FIELD.LAST_NAME.REQUIRED" | translate }}
                              } @else if (!userValue.isSpecificValid('lastNameMax')) {
                                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.LAST_NAME.TEXT" | translate) : ('' + validatorMaxLength64) }}
                              } @else if (!userValue.isSpecificValid('lastNameRequired')) {
                                {{ "USERS.USERS.FIELD.LAST_NAME.REQUIRED" | translate }}
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div id="user-image" class="d-flex flex-column position-relative">
                    <div *ngIf="hasUserImage && !licenseExpired"
                         id="image-delete-button"
                         (click)="onClearUserImage()"
                         class="position-absolute end-0 btn btn-rounded img-clear d-flex justify-content-center align-items-center">
                      <i class="font-size-18 text-danger" icon="close"></i>
                    </div>

                    @if (hasUserImage) {
                      @if (userImageChanged) {
                        <img class="avatar-xl border border-primary border-2 rounded-3 p-1"
                             [src]="'data:image/jpeg;base64,' + userImageData" alt="profile-picture">
                      } @else {
                        <img class="avatar-xl border border-primary border-2 rounded-3 p-1"
                             [src]="userImageData | Trust:'url'" alt="profile-picture">
                      }
                    } @else {
                      <img class="avatar-xl border border-primary border-2 rounded-3 p-1"
                           [src]="defaultImage" alt="profile-picture">
                    }
                    <div *ngIf="!licenseExpired"
                         class="d-flex position-relative flex-fill justify-content-center align-items-center cursor-pointer"
                         (click)="fileInput.click()">
                      <button
                        class="btn p-1 flex-grow-1 text-primary text-truncate">{{ "USERS.USERS.FIELD.IMAGE.CHANGE" | translate }}
                      </button>
                      <input #fileInput id="userImageSelector" hidden type="file" accept="image/png, image/jpeg"
                             (change)="onSelectImage(uploadPreview, $event)">
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-floating form-floating-custom mb-3" style="display: flex;">
                <div class="form-floating form-floating-custom flex-grow-1"
                     [ngClass]="{'sw-btn-n-b': isOnlineUser && !wasOfflineUser}">
                  <input [readonly]="licenseExpired"
                         type="text"
                         class="form-control"
                         id="mail"
                         name="mail"
                         (change)="userNameSuggestion(false)"
                         [(ngModel)]="userValue.value.mail"
                         [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('mail') || !lastMailIsFree,
                          'pending-changes': userValue.hasSpecificChanges('mail') && lastMailIsFree}"
                         [maxLength]="validatorMaxLength64"
                         placeholder="-">
                  <label for="mail">{{ "USERS.USERS.FIELD.MAIL.TEXT" | translate }}{{ isOnlineUser ? '*' : '' }}</label>
                  <div class="form-floating-icon">
                    <i icon="mail"></i>
                  </div>
                  @if (!userValue.isSpecificGroupValid('mail') || !lastMailIsFree) {
                    <div class="invalid-feedback" style="white-space: nowrap">
                      <div>
                        @if (!userValue.isSpecificValid('mailRequired')) {
                          {{ "USERS.USERS.FIELD.MAIL.REQUIRED" | translate }}
                        } @else if (!userValue.isSpecificValid('mailMax')) {
                          {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.MAIL.TEXT" | translate) : ('' + validatorMaxLength64) }}
                        } @else if (!userValue.isSpecificValid('mailPattern')) {
                          {{ "USERS.USERS.FIELD.MAIL.INVALID" | translate }}
                        } @else if (!lastMailIsFree) {
                          {{ "NOTIFICATION.TOAST.CES_ERROR.CES_ERR_MAIL_IN_USE" | translate }}
                        }
                      </div>
                    </div>
                  }
                </div>
                <div *ngIf="isOnlineUser && !wasOfflineUser" class="bg-ids-2-ces">
                  <div class="form-floating form-floating-custom" style="display: flex">
                    <div class="bg-ids-3-ces"
                         placement="left"
                         ngbTooltip="{{ ('USERS.USERS.FIELD.MAIL.VERIFICATION.' + userVerification.emailStatus.toUpperCase() + '.DETAIL') | translate }}">
                      <button class="btn text-primary btn-outline-danger"
                              [disabled]="userValue.hasSpecificChanges('username') ||
                                !userIsAllowedToResetPassword || userValue.value.uuid.length == 0 ||
                                userVerification.emailStatus.includes('pending') || verificationLoading"
                              (click)="triggerEMailVerification()"
                              style="height: 100%; width: 100%; padding-left: 0; border-color: #00000000;">
                        <div style="display:flex;">
                          @if (!isNewUser && !verificationLoading) {
                            <i style="width: 50px">
                              @if (userVerification.emailStatus.includes('verified')) {
                                <img src="assets/svgs/mail_ok.svg"
                                     style="height: 30px; width: 30px; margin-right: 10px; margin-left: 10px;">
                              } @else if (userVerification.emailStatus.includes('pending')) {
                                <img src="assets/svgs/mail_warning.svg"
                                     style="height: 30px; width: 30px; margin-right: 10px; margin-left: 10px;">
                              } @else {
                                <img src="assets/svgs/mail_cross.svg"
                                     style="height: 30px; width: 30px; margin-right: 10px; margin-left: 10px;">
                              }
                            </i>
                          }
                          <span
                            style="align-self: center;">{{ ('USERS.USERS.FIELD.MAIL.VERIFICATION.' + userVerification.emailStatus.toUpperCase() + '.TEXT') | translate }}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom mb-3" style="display: flex;">
                <div class="form-floating form-floating-custom flex-grow-1"
                     [ngClass]="{'sw-btn-n-b': isOnlineUser && !wasOfflineUser}">
                  <input [readonly]="licenseExpired"
                         type="text"
                         class="form-control"
                         id="username"
                         name="username"
                         (change)="userNameSuggestion(true)"
                         [(ngModel)]="userValue.value.username"
                         [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('username') || usernameIsTaken,
                          'pending-changes': userValue.hasSpecificChanges('username') && !usernameIsTaken}"
                         placeholder="-"
                         [maxLength]="validatorMaxLength32">
                  <label
                    for="username">{{ "USERS.USERS.FIELD.USERNAME.TEXT" | translate }}{{ isOnlineUser ? '*' : '' }}</label>
                  <div class="form-floating-icon">
                    <i icon="id"></i>
                  </div>
                  @if (!userValue.isSpecificGroupValid('username') || usernameIsTaken) {
                    <div class="invalid-feedback">
                      <div>
                        @if (!userValue.isSpecificValid('usernameRequired')) {
                          {{ "USERS.USERS.FIELD.USERNAME.REQUIRED" | translate }}
                        } @else if (!userValue.isSpecificValid('usernameMax')) {
                          {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.USERNAME.TEXT" | translate) : ('' + validatorMaxLength32) }}
                        } @else if (usernameIsTaken) {
                          {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrUniqueKeyViolationUsername" | translate }}
                        }
                      </div>
                    </div>
                  }
                </div>
                <div *ngIf="isOnlineUser && !wasOfflineUser" class="bg-ids-2-ces">
                  <div class="form-floating form-floating-custom" style="display: flex">
                    <div class="bg-ids-3-ces"
                         placement="left"
                         ngbTooltip="{{(isMailVerifiedOrUnknown ? ('AUTH.RESET_PASSWORD.SUBMIT' | translate) : ('USERS.USERS.FIELD.MAIL.VERIFICATION.NEEDED.TEXT' | translate))}}">
                      <button class="btn text-primary btn-outline-danger"
                              [disabled]="userValue.hasSpecificChanges('username')
                               || !isMailVerifiedOrUnknown
                               || !userIsAllowedToResetPassword
                               || userValue.value.uuid.length == 0"
                              (click)="resetPassword()"
                              style="height: 100%; width: 100%; padding-left: 0; border-color: #00000000;">
                        <div style="display:flex;">
                          <i style="font-size: 20px; width: 36px;" icon="password"></i>
                          <span style="align-self: center;">{{ "AUTH.RESET_PASSWORD.SUBMIT" | translate }}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isOnlineUser" class="form-floating form-floating-custom"
                [ngClass]="{'mb-3': !isSelectedUserLoggedIn}">
                <select
                  *ngIf="(canChangeUserRole && !isSelectedUserLoggedIn) || userValue.value.uuid.length == 0; else userRole"
                  class="form-select"
                  id="userRole"
                  [(ngModel)]="userValue.value.roleId"
                  [ngClass]="{ 'pending-changes': userValue.hasSpecificChanges('roleId')}"
                  name="roleId">
                  <option *ngFor="let role of ROLE"
                          [ngValue]="role.id">{{ ('USERS.USERS.ROLE.' + role.value.toUpperCase()) | translate }}
                  </option>
                </select>
                <ng-template #userRole>
                  <input disabled id="userRole" class="form-control" [value]="userRoleTranslationText | translate">
                </ng-template>
                <label for="userRole">{{ "USERS.USERS.FIELD.USER_ROLE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="security"></i>
                </div>
              </div>

              <div *ngIf="!isSelectedUserLoggedIn"
                   [ngClass]="{'mb-3': !isSelectedUserLoggedIn && !isNewUser && isOnlineUser}"
                   class="form-floating form-floating-custom mt-3">
                <div id="formBlocked" class="form-control"
                     [ngClass]="{ 'pending-changes': userValue.hasSpecificChanges('blocked')}"
                     [attr.readonly]="canChangeUserAccessSettings? undefined : true">
                  <div class="form-check form-switch form-switch-md form-switch-right"
                       [ngClass]="{'pointer-events-none': !canChangeUserAccessSettings}">
                    <input [switchDisabled]="!canChangeUserAccessSettings"
                           type="checkbox"
                           style="scale: 1.2;"
                           class="form-check-input"
                           id="blocked"
                           name="blocked"
                           [(ngModel)]="userValue.value.blocked">
                  </div>
                </div>
                <label for="blocked" class="switch-label-center">{{ "USERS.USERS.FIELD.BLOCKED.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="security"></i>
                </div>
              </div>

              <div *ngIf="!isSelectedUserLoggedIn && !isNewUser && isOnlineUser"
                   class="form-floating form-floating-custom mt-3">
                <div id="formChangePassword" class="form-control"
                     [ngClass]="{ 'pending-changes': userValue.hasSpecificChanges('changePassword')}"
                     [attr.readonly]="canChangeUserAccessSettings? undefined : true">
                  <div class="form-check form-switch form-switch-md form-switch-right"
                       [ngClass]="{'pointer-events-none': !canChangeUserAccessSettings}">
                    <input [switchDisabled]="!canChangeUserAccessSettings"
                           type="checkbox"
                           style="scale: 1.2;"
                           class="form-check-input"
                           id="changePassword"
                           name="changePassword"
                           [(ngModel)]="userValue.value.changePassword">
                  </div>
                </div>
                <label for="changePassword" class="switch-label-center">{{ "USERS.USERS.FIELD.CHANGE_PASSWORD.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="security"></i>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card h-100 shadow-none">
            <h6 class="card-header">{{ "USERS.USERS.SECTION.ADDRESS" | translate }}</h6>
            <div class="card-body">

              <div class="form-floating form-floating-custom mb-3">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="streetHouseNumber"
                       name="streetHouseNumber"
                       [(ngModel)]="userValue.value.streetHouseNumber"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('streetHouseNumber'), 'pending-changes': userValue.hasSpecificChanges('streetHouseNumber')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64">
                <label for="streetHouseNumber">{{ "USERS.USERS.FIELD.STREET_HOUSE_NUMBER.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="marker"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('streetHouseNumber')" class="invalid-feedback d-block">
                  <div>
                    {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.STREET_HOUSE_NUMBER.TEXT" | translate) : ('' + validatorMaxLength64) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom mb-3">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="zipCode"
                       name="zipCode"
                       [(ngModel)]="userValue.value.zipCode"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('zipCode'), 'pending-changes': userValue.hasSpecificChanges('zipCode')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength10">
                <label for="zipCode">{{ "USERS.USERS.FIELD.ZIP_CODE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="marker"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('zipCode')" class="invalid-feedback d-block">
                  <div>{{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.ZIP_CODE.TEXT" | translate) : ('' + validatorMaxLength10) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom mb-3">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="city"
                       name="city"
                       [(ngModel)]="userValue.value.city"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('city'), 'pending-changes': userValue.hasSpecificChanges('city')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64">
                <label for="city">{{ "USERS.USERS.FIELD.CITY.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="marker"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('city')" class="invalid-feedback d-block">
                  <div>
                    {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.CITY.TEXT" | translate) : ('' + validatorMaxLength64) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom mb-3">
                <select *ngIf="!licenseExpired && canChangeUserAccessSettings; else country"
                        class="form-select"
                        id="country"
                        name="countryId"
                        [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('countryId'), 'pending-changes': userValue.hasSpecificChanges('countryId')}"
                        [(ngModel)]="userValue.value.countryId">
                  <option *ngFor="let country of COUNTRY"
                          [value]="country.id">{{ ('COUNTRY.' + country.value.toUpperCase()) | translate }}
                  </option>
                </select>
                <ng-template #country>
                  <input disabled id="country" class="form-control" [value]="userCountryTranslationText | translate">
                </ng-template>
                <label for="country">{{ "USERS.USERS.FIELD.COUNTRY.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="marker"></i>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="d-flex flex-grow-1">
          <div class="card h-100 shadow-none">
            <h6 class="card-header">{{ "USERS.USERS.SECTION.DETAILS" | translate }}</h6>
            <div class="card-body d-flex flex-grow-1 flex-row flex-wrap"
                 [ngClass]="{ 'gap-3': detailsValid, 'gap-4': !detailsValid }">

              <div class="form-floating form-floating-custom flex-grow-1">
                <date-time-picker style="flex-grow: 1"
                                  titleText="USERS.USERS.FIELD.DATE_OF_BIRTH.TEXT"
                                  [readonly]="licenseExpired || !canChangeUserAccessSettings"
                                  [clearable]="true"
                                  [(dateValue)]="userValue.value.dateOfBirth"
                                  [showChanges]="userValue.hasSpecificChanges('dateOfBirth')">
                </date-time-picker>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="personnelNo"
                       name="personnelNo"
                       [(ngModel)]="userValue.value.personnelNo"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('personnelNo'), 'pending-changes': userValue.hasSpecificChanges('personnelNo')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64">
                <label for="personnelNo">{{ "USERS.USERS.FIELD.PERSONNEL_NO.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="id"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('personnelNo')" class="invalid-feedback d-block">
                  <div>
                    {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.PERSONNEL_NO.TEXT" | translate) : ('' + validatorMaxLength64) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="department"
                       name="department"
                       [(ngModel)]="userValue.value.department"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('department'), 'pending-changes': userValue.hasSpecificChanges('department')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64">
                <label for="department">{{ "USERS.USERS.FIELD.DEPARTMENT.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="info"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('department')" class="invalid-feedback d-block">
                  <div>
                    {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.DEPARTMENT.TEXT" | translate) : ('' + validatorMaxLength64) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="activity"
                       name="activity"
                       [(ngModel)]="userValue.value.activity"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('activity'), 'pending-changes': userValue.hasSpecificChanges('activity')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64">
                <label for="activity">{{ "USERS.USERS.FIELD.ACTIVITY.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="info"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('activity')" class="invalid-feedback d-block">
                  <div>
                    {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.ACTIVITY.TEXT" | translate) : ('' + validatorMaxLength64) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       type="text"
                       class="form-control"
                       id="area"
                       name="area"
                       [(ngModel)]="userValue.value.area"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('area'), 'pending-changes': userValue.hasSpecificChanges('area')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64">
                <label for="area">{{ "USERS.USERS.FIELD.AREA.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="info"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('area')" class="invalid-feedback d-block">
                  <div>
                    {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.AREA.TEXT" | translate) : ('' + validatorMaxLength64) }}
                  </div>
                </div>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       class="form-control"
                       id="phoneOffice"
                       name="phoneOffice"
                       [(ngModel)]="userValue.value.phoneOffice"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('phoneOffice'), 'pending-changes': userValue.hasSpecificChanges('phoneOffice')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64"
                       type="tel">
                <label for="phoneOffice">{{ "USERS.USERS.FIELD.PHONE_OFFICE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="phone"></i>
                </div>
                <div
                  *ngIf="!userValue.isSpecificGroupValid('phoneOffice') && (userValue.value.phoneOffice || '').length > 0"
                  class="invalid-feedback">
                  @if (!userValue.isSpecificValid('phoneOfficeMax')) {
                    <div>
                      {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.PHONE_OFFICE.TEXT" | translate) : ('' + validatorMaxLength64) }}
                    </div>
                  }
                </div>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       class="form-control"
                       id="phonePrivate"
                       name="phonePrivate"
                       [(ngModel)]="userValue.value.phonePrivate"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('phonePrivate'), 'pending-changes': userValue.hasSpecificChanges('phonePrivate')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64"
                       type="tel">
                <label for="phonePrivate">{{ "USERS.USERS.FIELD.PHONE_PRIVATE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="phone"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('phonePrivate')" class="invalid-feedback">
                  @if (!userValue.isSpecificValid('phonePrivateMax')) {
                    <div>
                      {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.PHONE_PRIVATE.TEXT" | translate) : ('' + validatorMaxLength64) }}
                    </div>
                  }
                </div>
              </div>

              <div class="form-floating form-floating-custom flex-grow-1">
                <input [readonly]="licenseExpired || !canChangeUserAccessSettings"
                       class="form-control"
                       id="phoneMobile"
                       name="phoneMobile"
                       [(ngModel)]="userValue.value.phoneMobile"
                       [ngClass]="{ 'is-invalid': !userValue.isSpecificGroupValid('phoneMobile'), 'pending-changes': userValue.hasSpecificChanges('phoneMobile')}"
                       placeholder="-"
                       [maxLength]="validatorMaxLength64"
                       type="tel">
                <label for="phoneMobile">{{ "USERS.USERS.FIELD.PHONE_MOBILE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="mobile"></i>
                </div>
                <div *ngIf="!userValue.isSpecificGroupValid('phoneMobile')" class="invalid-feedback">
                  @if (!userValue.isSpecificValid('phoneMobileMax')) {
                    <div>
                      {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("USERS.USERS.FIELD.PHONE_MOBILE.TEXT" | translate) : ('' + validatorMaxLength64) }}
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </form><!-- end form -->

    <!-- IMAGE UPLOAD MODAL -->
    <!-- ADD MODAL -->
    <ng-template #uploadPreview let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ "USERS.USERS.USER_IMAGE.TITLE" | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
      </div>
      <div id="cropper-content" class="modal-body">
        <image-cropper [aspectRatio]="1"
                       [maintainAspectRatio]="true"
                       [imageFile]="imageFile"
                       [roundCropper]="true"
                       [onlyScaleDown]="true"
                       [containWithinAspectRatio]="true"
                       format="png"
                       (imageCropped)="imageCropped($event)">
        </image-cropper>
        <div class="modal-content-size-hint">
          <span *ngIf="imageTooLarge"> {{ "USERS.USERS.USER_IMAGE.IMAGE_TOO_LARGE" | translate }} </span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn text-primary btn-outline-light"
                [disabled]="imageTooLarge"
                (click)="onSaveImage()">
          {{ "USERS.USERS.USER_IMAGE.SAVE" | translate }}
        </button>
        <button type="button" class="btn text-secondary btn-outline-light" (click)="modal.close()">
          {{ "USERS.USERS.USER_IMAGE.CANCEL" | translate }}
        </button>
      </div>
    </ng-template>
  </div>
}
