<div class="sticky-header">
  <app-pagetitle title="{{'MENU.ACCESS_PROFILES' | translate}}" (onSearch)="onSearch($event)"
                    [searchEntries]="searchEntries" [pagetitleActions]="pagetitleActions"
                    [disableButtons]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                    (onPagetitleAction)="onAddAccessProfile()" [itemSelected]="itemSelected"></app-pagetitle>
</div>

<div class="scrollable-content">
  <app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
                 (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)" (onItemReturn)="onResetView()"
                 [disableButtons]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                 [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'8'"
                 [multiSelectActions]="multiSelectActions" (onMultiListAction)="onMultiDeleteAction($event)">

    <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-tabs-custom nav-justified" [(activeId)]="selectedNavTab"
        [destroyOnHide]="false">
      <li ngbNavItem="accessProfile">
        <a ngbNavLink>{{ "ACCESS_PROFILES.TAB.GENERAL" | translate }}</a>
        <ng-template ngbNavContent>
          <app-access-profile-form [accessProfile]="itemForm" [accessReadonly]="accessReadonly || selectedItem?.name == '24/7'"
                                   [licenseTypeId]="licenseTypeId"
                                   [licenseNotValid]="licenseTypeId > 1 && !licenseIsValidForBusiness">
          </app-access-profile-form>
        </ng-template>
      </li>
      <li ngbNavItem="timeModels">
        <a ngbNavLink>{{ "ACCESS_PROFILES.TAB.TIME_MODELS" | translate }}</a>
        <ng-template ngbNavContent>
          <app-access-profile-time-model-form [assigned]="itemForm"
                                              [licenseNotValid]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                                              [accessReadonly]="accessReadonly || selectedItem?.name == '24/7'">
          </app-access-profile-time-model-form>
        </ng-template>
      </li>
      <li ngbNavItem="lockingDevices">
        <a ngbNavLink>{{ "ACCESS_PROFILES.TAB.LOCKING_DEVICES" | translate }}</a>
        <ng-template ngbNavContent>
          <app-access-profile-locking-device-form [licenseNotValid]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                                                  [licenseTypeId]="licenseTypeId"
                                                  [accessReadonly]="accessReadonly"
                                                  [assigned]="itemForm">
          </app-access-profile-locking-device-form>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-4"></div>

  </app-item-list>
</div>
