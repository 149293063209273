import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {LockingDeviceGroupFormComponent} from "./forms/locking-device-group-form/locking-device-group-form.component";
import {
  LockingDeviceParamsFormComponent
} from "../locking-device-devices/forms/locking-device-params-form/locking-device-params-form.component";
import {ItemManager} from "../../../shared/item-list/ItemManager";
import {
  ItemSaveAction,
  ListEditAction,
  ListViewAction,
  MultiDeleteAction,
  MultiListAction
} from "../../../shared/models/Actions";
import {ItemListFilter, ItemListItem} from "../../../shared/item-list/ItemListItem";
import {ApiService} from "../../../core/services/api-service/api.service";
import {ListActionEvent} from "../../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../../shared/models/ItemActionEvent";
import {SubmitUtils} from "../../../shared/util/SubmitUtils";
import {
  LockingDeviceGroupDevicesComponent
} from "./forms/locking-device-group-devices/locking-device-group-devices.component";
import {ItemListComponent} from "../../../shared/item-list/item-list.component";
import {TranslateService} from "@ngx-translate/core";
import {MultiListActionEvent} from "../../../shared/models/MultiListActionEvent";
import {ActivatedRoute} from "@angular/router";
import {DeviceGroupType} from "../../../core/enums/deviceGroupType"
import {TimeModelDto} from "../../../shared/entities/TimeModel/TimeModelDto";
import {LockingDeviceGroupDto} from "../../../shared/entities/locking-device/LockingDeviceGroupDto";
import {LockingDeviceGroupDetailsDto} from "../../../shared/entities/locking-device/LockingDeviceGroupDetailsDto";
import {LockingDeviceParamsDto} from "../../../shared/entities/locking-device/LockingDeviceParamsDto";
import {firstValueFrom, take} from "rxjs";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {ChangeDetectorChanges} from "../../../shared/util/change-detector/ChangeDetectorChanges";


@Component({
    selector: 'app-locking-device-groups',
    templateUrl: './locking-device-groups.component.html',
    styleUrls: ['./locking-device-groups.component.scss'],
    standalone: false
})
export class LockingDeviceGroupsComponent implements OnInit {
  _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<LockingDeviceGroupDto>
  @ViewChild(LockingDeviceGroupFormComponent) lockingDeviceGroupForm!: LockingDeviceGroupFormComponent
  @ViewChild(LockingDeviceGroupDevicesComponent) lockingDeviceGroupDevicesFrom!: LockingDeviceGroupDevicesComponent
  @ViewChild(LockingDeviceParamsFormComponent) lockingDeviceParamsForm!: LockingDeviceParamsFormComponent

  @Input() readonlyLicenseInvalid: boolean = false;

  @Input() set onSearch(searchString: string) {
    this.searchQuery = searchString
    this.searchFilter.triggerItemUpdate()
  }

  @Output() onUpdate = new EventEmitter<void>();

  // Items
  itemManager: ItemManager<LockingDeviceGroupDto>
  itemSelected = false
  selectedItem: LockingDeviceGroupDetailsDto | undefined;

  // Params and Devices
  timeModels: TimeModelDto[] = [];

  pendingFormChanges = false
  items: any = []

  selectedNavTab: 'lockingDeviceGroup' | 'lockingDevices' | 'groupParams' = 'lockingDeviceGroup';

  // Actions
  saveAction = new ItemSaveAction()

  multiSelectActions: MultiListAction[] = []
  multiDeleteAction = new MultiDeleteAction()
  groupId: string | null = null
  groupUuid: string | null = null

  accessReadonly = true
  canDeleteLockingDeviceGroup = false
  canAddLockingDeviceGroup = false
  canChangeLockingDeviceGroupAssignmentSettings = false

  // Access
  getAccessReadonly(): boolean {
    return this.apiService.lockingDeviceGroup.accessIsReadOnly;
  }

  getCanDeleteLockingDeviceGroup(): boolean {
    return this.apiService.lockingDeviceGroup.canDelete;
  }

  getCanAddLockingDeviceGroup(): boolean {
    return this.apiService.lockingDeviceGroup.canAdd;
  }

  getCanChangeLockingDeviceGroupAssignmentSettings(): boolean {
    return this.apiService.lockingDeviceGroup.canChange;
  }

  typeaheadIndex = new Set<string>()

  searchQuery: string = ""
  searchFilter = new ItemListFilter<LockingDeviceGroupDto>(item => {
    return String(item.name || "").toLowerCase().includes(this.searchQuery.toLowerCase())
  })

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.saveAction.disabled = true;
    this.itemManager = new ItemManager<LockingDeviceGroupDto>(this, notification);
  }

  async ngOnInit() {
    this.accessReadonly = this.getAccessReadonly();
    this.canDeleteLockingDeviceGroup = this.getCanDeleteLockingDeviceGroup();
    this.canAddLockingDeviceGroup = this.getCanAddLockingDeviceGroup();
    this.canChangeLockingDeviceGroupAssignmentSettings = this.getCanChangeLockingDeviceGroupAssignmentSettings();

    this._isLoading = true;
    this.groupId = this.route.snapshot.paramMap.get('id')
    this.groupUuid = this.route.snapshot.paramMap.get('uid')
    this.itemManager.setItems(await firstValueFrom(await this.apiService.lockingDeviceGroup.getAll()));
    this.itemManager.forEach((value => {
      this.typeaheadIndex.add(value.name)
    }));

    this.apiService.timeModel.getAll().then(observer => observer.pipe(take(1)).subscribe(value => {
      this.timeModels = value;
      this._isLoading = false;
    }));

    if (this.canDeleteLockingDeviceGroup) {
      this.multiSelectActions.push(this.multiDeleteAction);
    }

    this.onUpdate.emit();
  }

  get hasChanges(): boolean {
    return new ChangeDetectorChanges(
      this.lockingDeviceGroupForm.lockingDeviceDetails,
      this.lockingDeviceGroupDevicesFrom.changed,
      ...this.lockingDeviceParamsForm.changeDetectorChanges.detectors
    ).hasChanges;
  }

  handlePendingChanges() {
    let changed = this.hasChanges
    this.saveAction.disabled = !changed || !this.lockingDeviceParamsForm.changeDetectorChanges.isValid;
    this.pendingFormChanges = changed
    this.onUpdate.emit()
  }

  hasPendingChanges(): boolean {
    return this.pendingFormChanges
  }

  mapToItemList(item: LockingDeviceGroupDto): ItemListItem<LockingDeviceGroupDto> {

    const itemListItem = new ItemListItem(item.uuid, item.name, item)
      .addInfo(item.description ? item.description : '')
      .addAction(this.accessReadonly ? new ListViewAction() : new ListEditAction());

    switch (item.groupTypeId) {
      case DeviceGroupType.paramGroup:
        return itemListItem.setImage('assets/ces/locking-device/group/locking-device-params-group.svg');
      case DeviceGroupType.accessProfileGroup:
        return itemListItem.setImage('assets/ces/locking-device/group/locking-device-access-group.svg');
      default:
        return itemListItem.setImage('assets/ces/locking-device/group/locking-device-group.svg');
    }
  }

  onReturnEvent() {
    this.selectedNavTab = 'lockingDeviceGroup';
    this.selectedItem = undefined;
    this.lockingDeviceGroupForm.lockingDeviceDetails.reset();
    this.lockingDeviceGroupDevicesFrom.reset();
    this.handlePendingChanges();
    this.onUpdate.emit();
  }

  async onSelectEvent(actionEvent: ListActionEvent<LockingDeviceGroupDto>) {
    if (actionEvent == undefined) return

    if (actionEvent.item.uuid != "") {
      this.selectedItem = await firstValueFrom(await this.apiService.lockingDeviceGroup.get(actionEvent.item.uuid));
    }

    this.onUpdate.emit();

    if (!this.accessReadonly) {
      actionEvent.addItemAction(this.saveAction)
    }
  }

  async onEditEvent(actionEvent: ItemActionEvent<LockingDeviceGroupDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList)
    }

  }

  async onMultiDelete(event: MultiListActionEvent<LockingDeviceGroupDto>) {
    if (!this.canDeleteLockingDeviceGroup) {
      return
    }

    const ids: string[] = event.items.map(value => value.uuid);
    const firstFailedUuid = await this.apiService.lockingDeviceGroup.delete(ids);
    if (firstFailedUuid != undefined && firstFailedUuid.toString() != "") {
      this.notification.showError('NOTIFICATION.TOAST.CES_ERROR.cesErrExecution');
    } else {
      this.itemManager.removeItemsById(...ids);
    }
  }

  async onSubmit(returnToList: () => void) {
    if (SubmitUtils.reflectCheck(
        this.notification,
      this.lockingDeviceGroupForm.lockingDeviceDetails.isValid,
      this.lockingDeviceParamsForm.changeDetectorChanges.isValid)) {
      return;
    }

    let updatedLockingDeviceGroup: LockingDeviceGroupDto | undefined;

    let dto = this.lockingDeviceGroupForm.getLockingDeviceGroupDto(this.selectedItem!);
    dto.devices = this.lockingDeviceGroupDevicesFrom.getAssignments();
    dto.groupTypeId == DeviceGroupType.paramGroup ? dto.params = this.lockingDeviceParamsForm.getGroupsLockingDeviceParamsDto() : dto.params = undefined;
    if (dto.uuid == "") {
      updatedLockingDeviceGroup = await firstValueFrom(await this.apiService.lockingDeviceGroup.add(new LockingDeviceGroupDto(dto.uuid, dto.id, dto.name, dto.description, dto.groupTypeId, dto.params, dto.devices.map(device => device.uuid))));
    } else {
      updatedLockingDeviceGroup = await firstValueFrom(await this.apiService.lockingDeviceGroup.update(new LockingDeviceGroupDto(dto.uuid, dto.id, dto.name, dto.description, dto.groupTypeId, dto.params, dto.devices.map(device => device.uuid))));
      if (updatedLockingDeviceGroup != null) {
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.UPDATE');
      }
    }


    if (updatedLockingDeviceGroup != null) {
      this.selectedItem!.uuid = updatedLockingDeviceGroup.uuid;
      this.itemManager.updateItem(updatedLockingDeviceGroup, true);
    }

    this.selectedNavTab = 'lockingDeviceGroup';
    returnToList();
    this.onReturnEvent();
  }

  addNewGroup(groupTypeId: number) {
    if (!this.canAddLockingDeviceGroup) {
      return
    }

    this.selectedItem = LockingDeviceGroupDetailsDto.emptyLockingDeviceGroupDetailsDto();
    this.selectedItem.groupTypeId = groupTypeId;
    if (groupTypeId == DeviceGroupType.paramGroup) {
      this.selectedItem.params = LockingDeviceParamsDto.emptyLockingDeviceParamsDto();
    }

    const item = this.mapToItemList(new LockingDeviceGroupDto(this.selectedItem.uuid, this.selectedItem.id, this.selectedItem.name, this.selectedItem.description, this.selectedItem.groupTypeId, this.selectedItem.params, this.selectedItem.devices.map(device => device.uuid)));

    item.name = this.translate.instant("LOCKING_DEVICES.GROUPS.ACTION.NEW_GROUP")

    this.appItemList.setSelectedListItem(item, new ItemSaveAction())
  }

  get selectedParams(): LockingDeviceParamsDto | undefined {
    return this.selectedItem?.params ? this.selectedItem?.params : undefined;
  }

  get hideLockingDevicesTab(): boolean {
    return this.selectedItem?.groupTypeId != DeviceGroupType.paramGroup;
  }
}
