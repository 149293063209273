import {Component, OnDestroy, OnInit} from '@angular/core';

import {ApiService} from "../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {
  ItemAction,
  ItemDeleteAction,
  ItemSaveAction,
  ListEditAction,
  ListViewAction
} from "../../shared/models/Actions";
import {ListActionEvent} from "../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../shared/models/ItemActionEvent";
import {ItemListFilter, ItemListItem, ItemListMapper} from "../../shared/item-list/ItemListItem";
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {FormatterService} from "../../core/services/formatter-service/formatter.service";
import {ItemManager} from "../../shared/item-list/ItemManager";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {BehaviorSubject, debounceTime, firstValueFrom, Subject, take} from "rxjs";
import {LockingMediaDto} from "../../shared/entities/LockingMediaDto";
import {MEDIA_IMPLEMENTATION, MEDIA_STATE, MEDIA_TYPE} from "../../shared/lookup/media.lookup";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";
import {CommissionManager} from '../../core/services/auth-service/support-services/CommissionManager';
import {IoBridgeStatus} from 'src/app/core/enums/ioBridgeStatus';
import {environment} from 'src/environments/environment';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalCloseCause} from 'src/app/core/enums/modalCloseCause';
import {LockingMediaBindingComponent} from './modals/locking-media-binding/locking-media-binding.component';
import {LockingMediaUnbindingComponent} from './modals/locking-media-unbinding/locking-media-unbinding.component';
import {LockingMediaRebindingComponent} from './modals/locking-media-rebinding/locking-media-rebinding.component';

import {LockingMediaCdm} from "../../shared/util/change-detector/models/locking-media.cdm";
import {ModalContainerContent} from 'src/app/shared/notification/modal/modal-container.class';
import {ModalService} from 'src/app/shared/notification/modal/modal.service';
import {IoBridgeFlow} from 'src/app/core/enums/ioBridgeFlow';
import {UnleashService} from "../../core/services/unleash-service/unleash.service";

@Component({
  selector: 'app-locking-media',
  templateUrl: './locking-media.component.html',
  styleUrls: ['./locking-media.component.scss'],
  standalone: false
})
export class LockingMediaComponent implements OnInit, ItemListMapper<LockingMediaDto>, PendingChangesBlocker {
  private commissionManager: CommissionManager;

  handoutDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});
  returnDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});

  _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  itemManager: ItemManager<LockingMediaDto>;
  selectedItem?: LockingMediaDto;
  media: ChangeDetectorValue = new ChangeDetectorValue(undefined);
  itemSelected = false;

  saveAction = new ItemSaveAction();
  deleteAction = new ItemDeleteAction();

  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  accessReadonly = true;

  getAccessReadonly(): boolean {
    return this.apiService.lockingMedia.isReadonly() || (!this.licenseIsValidForBusiness && this.licenseTypeId > 1);
  }

  showArticleNumber = false;

  typeaheadIndex = new Set<string>();

  get searchEntries() {
    return [...this.typeaheadIndex.values()];
  }

  searchQuery: string = '';
  searchFilter = new ItemListFilter<LockingMediaDto>((item) => {
    return String(item.displayUid || '')
      .toLowerCase()
      .includes(this.searchQuery.toLowerCase());
  });

  canAddOrRemoveMedia = false

  ioBridgeConnected: boolean = false;

  generateUnbindAction: ItemAction = new ItemAction(
    "PROGRAMMING.MODAL.LOCKING_MEDIA.REMOVE",
    (): void => {
    },
    false,
    "btn-outline-danger",
    "mdi mdi-door-open");

  generateReplaceAction: ItemAction = new ItemAction(
    "PROGRAMMING.MODAL.LOCKING_MEDIA.REPAIR",
    (): void => {
    },
    false,
    "btn-outline-secondary",
    "mdi mdi-auto-fix");

  featureMediaUnbind: boolean = false;

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private formatter: FormatterService,
    private notification: ToastService,
    private modalService: NgbModal,
    private alertService: ModalService,
    private unleash: UnleashService,
  ) {
    this.itemManager = new ItemManager<LockingMediaDto>(this, notification, this.sortDisplayItemsDefault, this.sortDisplayedItems);
    this.commissionManager = CommissionManager.getInstance();
  }

  async ngOnInit() {
    this.canAddOrRemoveMedia = this.apiService.lockingMedia.isLicenseValid && this.apiService.lockingMedia.isLicenseBusiness && this.apiService.lockingMedia.isAdminOrHigher;
    this._isLoading = true;
    this.accessReadonly = this.getAccessReadonly();

    this.apiService.lockingMedia.getAll().then(observer => {
      observer.pipe(take(1)).subscribe({
        next: items => {
          this.itemManager.setItems(items);
          this.itemManager.forEach((value) => {
            this.typeaheadIndex.add(value.item.displayUid);
          });
          this._isLoading = false;
        },
        error: () => {
          this._isLoading = false;
        }
      });
    });

    this.licenseTypeId = this.apiService.lockingMedia.isLicenseBusiness ? 2 : 1;
    this.licenseIsValidForBusiness = this.licenseTypeId == 2 && this.apiService.lockingMedia.isLicenseValid;

    this.ioBridgeConnected = this.commissionManager.bindingProcess.ioBridgeStatus == IoBridgeStatus.CONNECTED;
    this.commissionManager.bindingProcessObservable.pipe(debounceTime(500)).subscribe({
      next: data => {
        this.ioBridgeConnected = data.ioBridgeStatus == IoBridgeStatus.CONNECTED;
      },
      error: () => {
        this.ioBridgeConnected = false;
      }
    });

    this.unleash.featureFlags.get(environment.featureFlags.mediaUnbind.name)?.pipe(debounceTime(500)).subscribe(f => this.featureMediaUnbind = f);
  }

  async onSelectEvent(actionEvent: ListActionEvent<LockingMediaDto>) {
    if (actionEvent == undefined) return;

    await this.onSelect(actionEvent.item);

    if (this.apiService.lockingMedia.isAdminOrHigher && CommissionManager.getInstance().bindingProcess.ioBridgeStatus == IoBridgeStatus.CONNECTED) {
      if (this.featureMediaUnbind) {
        if (actionEvent.item.mediaNumber < 100000) {
          actionEvent.addItemAction(this.generateUnbindAction);
          // actionEvent.addItemAction(this.generateReplaceAction);
        }
      }
    }

    if (actionEvent.action instanceof ListEditAction) {
      if (this.apiService.lockingMedia.canDeleteLockingMedia(actionEvent.item)) {
        actionEvent.addItemAction(this.deleteAction);
      }

      this.saveAction.disabled = true;
      actionEvent.addItemAction(this.saveAction);
    }
  }

  private async onSelect(item: LockingMediaDto) {
    this.selectedItem = item;
    let changeDetectorItem: LockingMediaCdm = new LockingMediaCdm();
    changeDetectorItem.values = item;
    this.media = new ChangeDetectorValue(changeDetectorItem, () => {
      this.saveAction.disabled = !(this.media.hasChanges && this.media.isValid);
    }, changeDetectorItem.validators());

    this.showArticleNumber = this.apiService.lockingMedia.getIsArticleNumberVisible(item);

  }

  get implementationType(): string {
    return this.translate.instant(`LOCKING_MEDIA.IMPLEMENTATION.${[...MEDIA_IMPLEMENTATION.filter(value => value.id == this.media.value?.implementationId), MEDIA_IMPLEMENTATION[0]][0].value.toUpperCase()}`);
  }

  get lockingMediaType(): string {
    return this.translate.instant(`LOCKING_MEDIA.TYPE.${[...MEDIA_TYPE.filter(value => value.id == this.media.value?.typeId), MEDIA_TYPE[0]][0].value.toUpperCase()}`);
  }

  mapToItemList(item: LockingMediaDto): ItemListItem<LockingMediaDto> {
    return new ItemListItem(item.uuid, item.displayUid, item) //TODO display mechanical ID since UID won't be displayed on self scanned media?
      .addInfo(this.formatter.formatArray(item.assignedUsers || [], this.translate.instant('LOCKING_MEDIA.ITEM_LIST.NO_USERS')))
      .setImage(`assets/ces/locking-media/${this.apiService.lockingMedia.getImageNameForMediaType(item.implementationId)}.svg`)
      .addAction(this.accessReadonly ? new ListViewAction() : new ListEditAction())
      .setColor((item.stateId == 3 || item.stateId == 4) ? '#404040' : undefined);
  }

  hasPendingChanges(): boolean {
    return this.media.hasChanges;
  }

  async onEditEvent(actionEvent: ItemActionEvent<LockingMediaDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList);
    }
    if (actionEvent.action instanceof ItemDeleteAction) {
      await this.onDelete(actionEvent.returnToList);
    }

    if (actionEvent.action == this.generateUnbindAction) {
      await this.modalComponent(actionEvent.returnToList, IoBridgeFlow.MEDIA_UNBIND)
    } // else if (actionEvent.action == this.generateReplaceAction) {
    //     await this.modalComponent(actionEvent.returnToList, IoBridgeFlow.MEDIA_REBIND)
    // }
  }

  resetForms() {
    this.media.reset();
  }

  async onSubmit(returnToList: () => void) {
    // stop here if form is invalid
    if (SubmitUtils.reflectCheck(this.notification, true, this.media.isValid)) {
      return;
    }

    if (this.media.value.stateId == 3 || this.media.value.stateId == 4) {
      const modalContent: ModalContainerContent = new ModalContainerContent(
        this.translate.instant("LOCKING_MEDIA.STATE.WARNING.TITLE"),
        this.translate.instant('LOCKING_MEDIA.STATE.WARNING.MESSAGE', {state: this.translate.instant(this.media.value.stateId == 3 ? 'LOCKING_MEDIA.STATE.BROKEN' : 'LOCKING_MEDIA.STATE.LOST').toLowerCase()})
      );
      if (await this.alertService.openModal(modalContent)) {
        (await this.apiService.lockingMedia.update(this.media.value.toLockingMediaDto)).subscribe({
          next: value => {
            this.itemManager.updateItem(value);
            this.resetForms();
            returnToList();
          }
        });
      }
    } else {
      (await this.apiService.lockingMedia.update(this.media.value.toLockingMediaDto)).subscribe({
        next: value => {
          this.itemManager.updateItem(value);
          this.resetForms();
          returnToList();
        }
      });
    }
  }

  // Für Meilenstein 2
  private async modalComponent(returnToList: () => void, flow: IoBridgeFlow) {
    this.commissionManager = CommissionManager.getInstance(flow).init(this.selectedItem)
    this.modalService.open(flow == IoBridgeFlow.MEDIA_UNBIND ? LockingMediaUnbindingComponent : LockingMediaRebindingComponent).closed.pipe(debounceTime(500)).subscribe(async closeReason => {
      console.log(typeof closeReason, closeReason)
      // switch (typeof closeReason) {
      //     case "number":
      //     case "object":
      //         if (closeReason == (ModalCloseCause.SUCCESS || 3)) {
      //             this.removeCurrentItem();
      //             // if (CommissionManager.getInstance().bindingProcess.flow == IoBridgeFlow.MEDIA_REBIND) {
      //             //   await this.ngOnInit();
      //             // }
      //             returnToList();
      //             this.onReturnEvent();
      //         }
      //         break;
      //     case "string":
      //         let item = this.removeCurrentItem();
      //         item.serialNumber = closeReason;
      //         this.selectedItem = item as LockingDeviceDto;
      //         this.itemManager.addItems(item as LockingDeviceDto);
      //         const changesWereMade: boolean = this.lockingDeviceForm.changed || this.lockingDeviceParamsForm.changeDetectorChanges.hasChanges;
      //         this.lockingDeviceForm.lockingDeviceForm.patchValue({serialNumber: item.serialNumber});
      //         if (!changesWereMade) {
      //             this.lockingDeviceForm.lockingDeviceForm.markAsUntouched();
      //             this.lockingDeviceForm.changeDetector.refresh();
      //         }
      //         break;
      // }
    });
  }

  private removeCurrentItem(): any {
    let selectedItemPuffer: any = this.selectedItem!;
    this.itemManager.removeItems(this.selectedItem!);
    return selectedItemPuffer
  }

  onSearch(search: string) {
    this.searchQuery = search;
    this.searchFilter.triggerItemUpdate();
  }

  private async onDelete(returnToList: () => void) {
    if (await firstValueFrom(await this.apiService.lockingMedia.delete(this.selectedItem!.uuid))) {
      this.itemManager.removeItemsById(this.selectedItem!.uuid);
      this.resetForms();
      returnToList();
    }
  }

  onAddMedia() {
    let addedMedia = false;

    let mediaNumber: number[] = []
    this.itemManager.forEach(i => i.item.mediaNumber < 100000 && i.item.stateId != 3 && i.item.stateId != 4 ? mediaNumber.push(i.item.mediaNumber) : null)
    mediaNumber.sort((a, b) => b - a)
    this.commissionManager = CommissionManager.getInstance(IoBridgeFlow.MEDIA_BIND).init();
    this.commissionManager.bindingProcess.serialNumber = `${mediaNumber[0] + 1 < 100000 ? mediaNumber[0] + 1 : ''}`

    const commissioningModal: NgbModalRef = this.modalService.open(LockingMediaBindingComponent);

    commissioningModal.closed.pipe(debounceTime(500)).subscribe(async closeReason => {
      if (typeof closeReason === "object" && closeReason.uuid != undefined) {
        this.itemManager.addItems(closeReason as LockingMediaDto);
        this.itemManager.forEach((value => {
          this.typeaheadIndex.add(value.name)
        }));

      } else if (typeof closeReason === "number" && closeReason == ModalCloseCause.SUCCESS) {
        // Uuid was not returned. Therefore, a fallback with a complete reload
        await this.reload(addedMedia, true)
      } else {
        await this.reload(addedMedia)
      }
    });

    commissioningModal.dismissed.subscribe(async () => {
      await this.reload(addedMedia)
    })

    commissioningModal.componentInstance.getAddedMedia().subscribe((res: boolean) => addedMedia = res)
  }

  async reload(addedMedia: boolean, skipCheck?: boolean) {
    if (addedMedia || skipCheck) {
      await this.ngOnInit();
    }
  }

  sortDisplayedItems(items: Map<string, ItemListItem<LockingMediaDto>>) {
    const itemsArray = Array.from(items).sort((a, b) => a[1].item.mediaNumber - b[1].item.mediaNumber);

    let itemsState3 = itemsArray.filter(i => i[1].item.stateId == 3);
    let itemsState4 = itemsArray.filter(i => i[1].item.stateId == 4);
    let itemsStateO = itemsArray.filter(i => i[1].item.stateId != 3 && i[1].item.stateId != 4);

    items = new Map(itemsStateO.concat(itemsState3).concat(itemsState4))
    return items
  }

  sortDisplayItemsDefault(items: Map<string, ItemListItem<LockingMediaDto>>) {
    const itemsArray = Array.from(items).sort((a, b) => a[1].item.mediaNumber - b[1].item.mediaNumber);
    items = new Map(itemsArray)
    return items
  }

  get stateTranslationText(): string {
    return `LOCKING_MEDIA.STATE.${[...MEDIA_STATE.filter(value => `${this.media.value.stateId}` == `${value.id}`), MEDIA_STATE[0]][0].value.toUpperCase()}`;
  }

  protected readonly MEDIA_STATE = MEDIA_STATE;
  protected readonly environment = environment;
}
