import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ItemListComponent} from "../../../shared/item-list/item-list.component";
import {ItemManager} from "../../../shared/item-list/ItemManager";
import {
  ItemSaveAction, ItemSplittAction,
  ListEditAction,
  MultiDeleteAction,
  MultiListAction,
  MultiListSplittAction
} from "../../../shared/models/Actions";
import {ItemListFilter, ItemListItem, ItemListMapper} from "../../../shared/item-list/ItemListItem";
import {UserFormComponent} from "./user-forms/user-form/user-form.component";
import {UserAccessProfileFormComponent} from "./user-forms/user-access-profile-form/user-access-profile-form.component";
import {UserLockingMediaFormComponent} from "./user-forms/user-locking-media-form/user-locking-media-form.component";
import {ApiService} from "../../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {MultiListActionEvent} from "../../../shared/models/MultiListActionEvent";
import {ListActionEvent} from "../../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../../shared/models/ItemActionEvent";
import {SubmitUtils} from "../../../shared/util/SubmitUtils";
import {PendingChangesBlocker} from "../../../core/guards/pending-changes-view.guard";
import {UserRequestDto} from "../../../shared/entities/user/UserRequestDto";
import {firstValueFrom} from "rxjs";
import {ROLE} from "../../../shared/lookup/role.lookup";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {ChangeDetectorChanges} from "../../../shared/util/change-detector/ChangeDetectorChanges";
import {ChangeDetectorValue} from "../../../shared/util/change-detector/ChangeDetectorValue";
import {UserWithImageCdm} from "../../../shared/util/change-detector/models/user-with-image.cdm";
import {cloneDeep} from "lodash";
import {MultiListSplittActionEvent} from "../../../shared/models/MultiListSplittActionEvent";
import {EmailStatus} from "../../../core/enums/emailStatus.enum";
import {Helper} from "../../../shared/util/helper";

export const defaultImage: string = function (): string {
  const themeDesign = document.body.getAttribute("data-bs-theme");
  if (themeDesign != null && themeDesign.includes('black')) {
    return "assets/ces/user/user-black.svg"
  }
  return "assets/ces/user/user.svg"
}()

@Component({
    selector: 'app-user-users',
    templateUrl: './user-users.component.html',
    styleUrls: ['./user-users.component.scss'],
    standalone: false
})
export class UserUsersComponent implements OnInit, ItemListMapper<UserWithImageCdm>, PendingChangesBlocker {
  _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<UserWithImageCdm>

  @Input() set onSearch(search: string) {
    this.searchQuery = search
    this.searchFilter.triggerItemUpdate()
  }

  @Output() onUpdate = new EventEmitter<void>()

  itemManager: ItemManager<UserWithImageCdm>;
  selectedItem: UserWithImageCdm | undefined;
  itemSelected = false;

  skipSave:boolean = false;

  // Actions
  saveAction = new ItemSaveAction()

  multiSelectActions: MultiListAction[] = []
  multiDeleteAction = new MultiDeleteAction()

  multiSelectSplittActions: MultiListSplittAction = new MultiListSplittAction('USERS.USERS.ACTION.ACTIONS', [
    new ItemSplittAction('AUTH.RESET_PASSWORD.SUBMIT', uuids => {
      let allValid: boolean[] = [];
      this.itemManager.forEach((item) => {
        if (uuids.find(value => value == item.item.uuid)) {
          allValid.push((item.item.mail.length > 0 && item.item.username.length > 0 && item.item.emailStatus != (EmailStatus.Pending || 'pending')));
        }
      });
      return {
        valid: allValid.filter(value => !value).length == 0,
        translationText: 'NOTIFICATION.TOAST.WARNING.OFFLINE_USER'
      };
    }),
    new ItemSplittAction('USERS.USERS.FIELD.BLOCKED.BLOCK.TEXT', uuids => {
      let allValid: boolean[] = [];
      this.itemManager.forEach((item) => {
        if (uuids.find(value => value == item.item.uuid)) {
          allValid.push(!item.item.blocked);
        }
      });
      return {valid: allValid.filter(value => !value).length == 0, translationText: 'ALREADY_BLOCKED_USER'};
    }),
    new ItemSplittAction('USERS.USERS.FIELD.BLOCKED.UNBLOCK.TEXT', uuids => {
      let allValid: boolean[] = [];
      this.itemManager.forEach((item) => {
        if (uuids.find(value => value == item.item.uuid)) {
          allValid.push(item.item.blocked);
        }
      });
      return {
        valid: allValid.filter(value => !value).length == 0,
        translationText: 'ALREADY_UNBLOCKED_USER'
      };
    })
  ], '', 'arrow-down');

  // Data
  userAccessProfileUuids: ChangeDetectorValue = new ChangeDetectorValue(['']);
  userLockingMediaUuids: ChangeDetectorValue = new ChangeDetectorValue(['']);

  // License
  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  // Search
  typeaheadIndex = new Set<string>()

  canChangeUserRole: boolean = false
  canChangeUserAccessSettings: boolean = false
  isLicenseExpiredForUser: boolean = false

  get searchEntries() {
    return [...this.typeaheadIndex.values()]
  }

  searchQuery: string = ""
  searchFilter = new ItemListFilter<UserRequestDto>(item => {
    return String(`${item.firstName} ${item.lastName}`).toLowerCase().includes(this.searchQuery.toLowerCase())
  })

  // Tabs
  selectedNavTab: 'user' | 'accessProfiles' | 'lockingMedia' = 'user'

  pendingFormChanges: boolean = false;

  // Forms
  @ViewChild(UserFormComponent) userForm!: UserFormComponent
  @ViewChild(UserAccessProfileFormComponent) userAccessProfileForm?: UserAccessProfileFormComponent
  @ViewChild(UserLockingMediaFormComponent) userLockingMediaForm?: UserLockingMediaFormComponent

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService
  ) {
    this.itemManager = new ItemManager<UserWithImageCdm>(this, notification);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.canChangeUserAccessSettings = this.getCanChangeUserAccessSettings();
    this.isLicenseExpiredForUser = await this.getIsLicenseExpiredForUser()
    await this.loadItemsAndBuildTypeahead()

    if (this.apiService.user.canDelete) {
      this.multiSelectActions.push(this.multiDeleteAction);
    }

    this.licenseIsValidForBusiness = this.apiService.user.isLicenseBusiness && !this.apiService.user.isLicenseValid;
    this.licenseTypeId = this.apiService.user.isLicenseValid ? 2 : 1;
  }

  async loadItemsAndBuildTypeahead() {
    this.apiService.user.getAll().then(value => value.subscribe({
      next: userDtos => {
        this.itemManager.setItems(userDtos.map(userDto => new UserWithImageCdm().values = userDto) as UserWithImageCdm[]);
        this.typeaheadIndex.clear()
        this.itemManager.forEach(value => {
          this.typeaheadIndex.add(`${value.item.firstName} ${value.item.lastName}`)
        })

        this.onUpdate.emit();
        this._isLoading = false;
      }
    }));
  }

  // Access
  async getCanChangeUserRole(): Promise<boolean> {
    if (this.selectedItem == null) {
      return false;
    }
    if (this.apiService.user.session!.userUUID == this.selectedItem.uuid.toLowerCase()) {
      return false;
    }
    return this.apiService.user.isAdminOrHigher;
  }

  getCanChangeUserAccessSettings(): boolean {
    return this.apiService.user.canChange;
  }

  async getIsLicenseExpiredForUser(): Promise<boolean> {
    return (!this.licenseIsValidForBusiness && this.licenseTypeId > 1);
  }

  getLicenseTypeId(): number {
    return this.licenseTypeId;
  }

  private get changeDetector(): ChangeDetectorChanges {
    return new ChangeDetectorChanges(
      this.userForm.userValue,
      this.userForm.userImageChanged,
      this.userAccessProfileForm!.userAccessProfiles,
      this.userLockingMediaForm!.userLockingMedia);
  }

  // Interfaces
  checkPendingChanges() {
    this.pendingFormChanges = this.changeDetector.hasChanges;
    this.saveAction.disabled = !this.changeDetector.hasChanges || !this.changeDetector.isValid;
  }

  verificationTriggered() {
    const user: UserWithImageCdm = this.userForm.user;
    user.emailStatus = this.userForm.userVerification.emailStatus;
    this.itemManager.updateItem(user);
  }

  hasPendingChanges(): boolean {
    return this.pendingFormChanges
  }

  mapToItemList(item: UserWithImageCdm): ItemListItem<UserWithImageCdm> {
    let listItem = new ItemListItem<UserWithImageCdm>(
      item.uuid,
      `${item.firstName} ${item.lastName}`,
      item);

    if (item.blocked) {
      listItem.addBadge('mdi mdi-shield-alert-outline', 'rounded-pill badge-soft-primary blocked-user-badge',
        this.translate.instant('USERS.USERS.FIELD.BLOCKED.BLOCK.DETAIL'));
    }

    switch (item.emailStatus) {
      case EmailStatus.Expired || 'expired':
        listItem.addBadge('',
          'rounded-pill badge-soft-primary',
          this.translate.instant('USERS.USERS.FIELD.MAIL.VERIFICATION.EXPIRED.DETAIL'),
          'assets/svgs/mail_cross.svg');
        break;
      case EmailStatus.Pending || 'pending':
        listItem.addBadge('',
          'rounded-pill badge-soft-primary',
          this.translate.instant('USERS.USERS.FIELD.MAIL.VERIFICATION.PENDING.DETAIL'),
          'assets/svgs/mail_warning.svg');
        break;
    }

    listItem.addInfo(`${this.translate.instant([...ROLE.filter(role => role.id == item.roleId).map(user => `USERS.USERS.ROLE.${user.value.toUpperCase()}`), 'USERS.USERS.ROLE.UNKNOWN'][0])}`);
    //listItem.addInfo(`${this.translate.instant('USERS.USERS.FIELD.USER_ROLE.TEXT')} ${this.translate.instant([...ROLE.filter(role => role.id == item.roleId).map(user => `USERS.USERS.ROLE.${user.value.toUpperCase()}`), 'USERS.USERS.ROLE.UNKNOWN'][0])}`);
    listItem.addInfo(this.translate.instant((this.translate.instant('ACCESS_PROFILES.LIST.LOCKING_DEVICE_GROUPS_COUNT') as string).replace('{{groups}}', `${item.inGroups || '0'}`)));

    // set default image
    item.userImage = defaultImage;
    listItem.setImage(defaultImage);

    // load image if available
    if (item.imageAvailable) {
      // TODO sceleton
      item.userImage = defaultImage;
      this.apiService.user.getUserImage(item.uuid).then((base64Image) => {
        if ((base64Image || '').length == 0) return;
        item.userImage = `data:image/*;base64,${base64Image}`;
        listItem.setImage(item.userImage);
      })
    }

    listItem.addAction(new ListEditAction());

    return listItem;
  }

  async onMultiSplittAction(event: MultiListSplittActionEvent<UserRequestDto>) {
    let uuids: string[] = event.items.map(value => value.uuid);
    if (uuids.length == 0) {
      return;
    }
    let success: boolean = false;
    const validChecker = event.action.validChecker(uuids);
    if (!validChecker.valid) {
      this.notification.showWarning(`NOTIFICATION.TOAST.WARNING.${validChecker.translationText.replace('NOTIFICATION.TOAST.WARNING.', '')}`);
      uuids = uuids.filter(uuid => event.action.validChecker([uuid]).valid);
      if (uuids.length == 0) {
        return;
      }
    }
    setTimeout(async () => {
      switch (event.action.key) {
        case 'AUTH.RESET_PASSWORD.SUBMIT':
          success = await this.resetPassword(uuids);
          break;
        case 'USERS.USERS.FIELD.BLOCKED.BLOCK.TEXT':
          success = await this.apiService.user.blockUnblock(uuids, true);
          break;
        case 'USERS.USERS.FIELD.BLOCKED.UNBLOCK.TEXT':
          success = await this.apiService.user.blockUnblock(uuids, false);
          break;
        default:
          this.notification.showError();
      }
      if (success) {
        this.loadItemsAndBuildTypeahead()
          .finally(() => this.notification.showSuccess());
      }
      // Time for the user to read on warning
    }, validChecker.valid ? 1 : 3000);
  }

  async onMultiDeleteAction(event: MultiListActionEvent<UserRequestDto>) {
    let allSuccess = true
    if (event.items.length == 1 && (this.apiService.user.session!.userUUID == event.items[0].uuid)) {
      this.notification.showError(this.translate.instant("USERS.USERS.ACTION.DELETE_SELF"))
      return;
    }

    for (const user of event.items) {
      if (!await this.apiService.user.delete(user.uuid, true)) {
        allSuccess = false
      }
    }

    await this.loadItemsAndBuildTypeahead()

    if (allSuccess) {
      this.notification.showSuccess(`NOTIFICATION.TOAST.SUCCESS.DELETE`)
      return
    }

    this.notification.showWarning(this.translate.instant("USERS.USERS.ACTION.DELETE_WARNING"))
  }

  onAddUser() {
    let user: UserWithImageCdm = new UserWithImageCdm();
    this.selectedItem = user;
    user.userImage = defaultImage;

    this.onUpdate.emit();

    const listItem = new ItemListItem<UserWithImageCdm>(user.uuid, this.translate.instant("USERS.USERS.ACTION.NEW_USER_NAME"), user);

    // TODO lookup
    listItem.addInfo(this.translate.instant("USERS.USERS.ROLE.UNKNOWN"));


    listItem.setImage(defaultImage);

    this.appItemList.setSelectedListItem(listItem, new ItemSaveAction());
    this.setAssignedUuids();
  }

  onResetView() {
    this.selectedNavTab = 'user'

    this.userForm.reset();

    this.userAccessProfileForm?.reset();
    this.userLockingMediaForm?.reset();

    this.selectedItem = undefined;

    this.saveAction.disabled = true;
    this.pendingFormChanges = false;

    this.onUpdate.emit();
  }

  async onSelectEvent(actionEvent: ListActionEvent<UserRequestDto>) {
    if (actionEvent == undefined) return

    await this.onSelect(actionEvent.item);

    actionEvent.addItemAction(this.saveAction);

    this.saveAction.disabled = true;
  }

  private async onSelect(item: UserRequestDto) {
    let user: UserWithImageCdm = Helper.TO_USER_WITH_IMAGE(await firstValueFrom(await this.apiService.user.getUser(item.uuid)));
    this.selectedItem = cloneDeep(user);
    this.selectedItem.userImage = defaultImage;
    if (this.selectedItem.imageAvailable && this.itemManager.items.has(this.selectedItem.uuid)) {
      this.selectedItem.userImage = (this.itemManager.items.get(this.selectedItem.uuid) || {image: defaultImage}).image;
    }

    this.canChangeUserRole = await this.getCanChangeUserRole();

    this.setAssignedUuids();
    this.onUpdate.emit();
  }

  private setAssignedUuids() {
    this.userAccessProfileUuids = new ChangeDetectorValue(this.selectedItem?.accessProfiles, () => {
      this.checkPendingChanges();
    });
    this.userLockingMediaUuids = new ChangeDetectorValue(this.selectedItem?.media, () => {
      this.checkPendingChanges();
    });
  }

  async onEditEvent(actionEvent: ItemActionEvent<UserRequestDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList)
    }
  }

  async onSubmit(returnToList: () => void) {
    if (this.skipSave) {
      return;
    }
    this.skipSave = true;
    if (SubmitUtils.reflectCheck(this.notification, true, this.changeDetector.hasChanges, this.changeDetector.isValid, this.userForm.lastMailIsFree, !this.userForm.usernameIsTaken)) {
      this.skipSave = false;
      return
    }

    let success: boolean = true;
    let user: UserWithImageCdm = this.userForm.user;

    // add / update user
    if (this.selectedItem != undefined) {

      user.accessProfiles = this.emptyArrayToUndefined(this.userAccessProfileForm!.userAccessProfiles.value);
      user.media = this.emptyArrayToUndefined(this.userLockingMediaForm!.userLockingMedia.value);

      const result: UserRequestDto | null = await firstValueFrom(this.userForm.isNewUser ?
        await this.apiService.user.add((user as UserWithImageCdm).toUserRequestDto) :
        await this.apiService.user.update((user as UserWithImageCdm).toUserRequestDto));

      result ? user.values = result : success = false;
    }

    // update user image
    if (success && this.userForm.requireUserImageUpdate()) {
      if (user.uuid == "") {
        user.uuid = this.userForm.user.uuid;
        user.imageAvailable = this.userForm.user.imageAvailable;
      }
      if (this.userForm.hasUserImage) {
        const imageBlob = this.userForm.getUserImage();
        if (imageBlob == undefined) {
          user.imageAvailable = false;
        } else {
          success = await this.apiService.user.uploadUserImage(user.uuid, imageBlob);
          user.imageAvailable = true;
        }
      } else if (user.imageAvailable) {
        success = await this.apiService.user.deleteUserImage(user.uuid)
        user.imageAvailable = false
      }

      if (success && this.apiService.user.session!.userUUID == user.uuid) {
        this.apiService.user.userImage = user.imageAvailable ? `data:image/*;base64,${this.userForm.getUserImageData()}` : defaultImage;
      }
    }

    if (!success) {
      this.notification.showError();
    } else {
      this.notification.showSuccess();
      this.itemManager.updateItem(user, this.userForm.isNewUser);
      returnToList();
      this.onResetView();
    }
    this.skipSave = false;
  }

  private emptyArrayToUndefined(array: any[]): any[] | undefined {
    if (Array.isArray(array) && array.length > 0) {
      return array.filter(item => item != null);
    }
    return undefined;
  }

  async resetPassword(uuids: string[]): Promise<boolean> {
    if (this.apiService.auth.system) {
      for (const uuid of uuids) {
        if (!await this.apiService.auth.resetPassword(this.apiService.auth.system!, this.itemManager.items.get(uuid)!.item.username)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}
