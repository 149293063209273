import {Component, EventEmitter, Input, Output} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChangeDetectorForm} from "../../../../../shared/util/change-detector/ChangeDetectorForm";
import {UserGroupDetailsDto} from "../../../../../shared/entities/user-group/UserGroupDetailsDto";
import {UserGroupDto} from "../../../../../shared/entities/user-group/UserGroupDto";

@Component({
    selector: 'app-user-group-form',
    templateUrl: './user-group-form.component.html',
    styleUrls: ['./user-group-form.component.scss'],
    standalone: false
})
export class UserGroupFormComponent {

  validatorMaxLength64: number = 64;
  validatorMaxLength32: number = 32;

  @Input() accessReadonly = false

  _selectedItem: UserGroupDetailsDto | undefined;
  @Input() set userGroup(groupDto: UserGroupDetailsDto) {
    if(groupDto == null) {
      return
    }

    this._selectedItem = groupDto

    this.groupForm.patchValue({
      uuid: groupDto.uuid,
      id: groupDto.id,
      name: groupDto.name,
      description: groupDto.description || ''
    }, {emitEvent: false})

    this.changeDetector.refresh()
  }

  @Output() onChanges = new EventEmitter<void>()

  // Form
  groupForm: FormGroup;
  changeDetector: ChangeDetectorForm

  constructor(
    formBuilder: FormBuilder
  ) {
    this.groupForm = formBuilder.group({
      id: ['', []],
      uuid: ['', []],
      name: ['', [Validators.required, Validators.maxLength(this.validatorMaxLength32)]],
      description: ['', [Validators.maxLength(this.validatorMaxLength64)]]
    })

    this.changeDetector = new ChangeDetectorForm(this.groupForm, () => { this.onChanges.emit() })
  }

  get f() { return this.groupForm.controls; }

  get changed(): boolean {
    return this.changeDetector.changed
  }

  reset() {
    this.groupForm.reset()
    this.groupForm.markAsUntouched()
    this.changeDetector.refresh()
  }

  getUserGroupDto(): UserGroupDto {
    // update saved dto
    this._selectedItem!.name = this.groupForm.get("name")?.value;
    this._selectedItem!.description = this.groupForm.get("description")?.value;

    return new UserGroupDto(
      this._selectedItem!.uuid,
      this._selectedItem!.id,
      this._selectedItem!.name,
      this._selectedItem?.description,
      undefined,
      undefined,
    );
  }

  get nameTranslate(): string {
    return this.getLabelByIdForTranslation("name");
  }

  get descriptionTranslate(): string {
    return this.getLabelByIdForTranslation("description");
  }

  private getLabelByIdForTranslation(id: string): string {
    try {
      // @ts-ignore
      return document.getElementById(id).labels[0].innerHTML;
    } catch (e) {
      return '';
    }
  }
}
