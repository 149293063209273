import {Component, OnInit} from '@angular/core';

import {ITimeModelDynamicForm} from "../ITimeModelDynamicForm";
import {AbstractControl} from "@angular/forms";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";
import {TimeModelIntervalDto} from "../../../../shared/entities/TimeModel/TimeModelIntervalDto";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
    selector: 'app-time-model-point-in-time-form',
    templateUrl: './time-model-point-in-time-form.component.html',
    styleUrls: ['./time-model-point-in-time-form.component.scss'],
    standalone: false
})
export class TimeModelPointInTimeFormComponent implements OnInit, ITimeModelDynamicForm {
  private _startDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9}, () => { this.onChanges() });
  private _timeStart: ChangeDetectorValue = new ChangeDetectorValue({hour: 0, minute: 0}, () => { this.onChanges() });
  private _timeEnd: ChangeDetectorValue = new ChangeDetectorValue({hour: 0, minute: 0}, () => { this.onChanges() });

  private timeModelId: number = -1;

  private _accessReadonly: boolean = false;
  readonly customFormEnabled: boolean = true;
  readonly intervalEnabled: boolean = false;

  onChanges(): void {
  }

  get changed(): boolean {
    return this.timeStartChanged || this.timeEndChanged || this.startDateChanged;
  }

  get dynamicForm(): AbstractControl | boolean | null {
    return !this.invalid;
  }

  get invalid(): boolean {
    return !(this._timeStart.value.hour < this._timeEnd.value.hour ||
      (this._timeStart.value.hour == this._timeEnd.value.hour &&
        this._timeStart.value.minute < this._timeEnd.value.minute));
  }

  constructor(
    private formatter: FormatterService) {
  }

  ngOnInit(): void {
  }

  initialize(timeModel: TimeModelDto, accessReadonly: boolean): void {
    this._accessReadonly = accessReadonly;
    if (timeModel == null) return;

    this.timeModelId = timeModel.timeModelId;

    this._startDate.value = this.formatter.formatToNgbDatePicker(undefined);
    if (timeModel.startDate != undefined && timeModel.startDate != 0) {
      this._startDate.value = this.formatter.formatToNgbDatePicker(timeModel.startDate);
    }

    const firstInterval: TimeModelIntervalDto = timeModel.intervals?.[0];
    this._timeStart.value = firstInterval ? this.formatter.formatToNgbTimePicker(firstInterval.timeStart) : this.formatter.formatToNgbTimePicker(0);
    this._timeEnd.value = firstInterval ? this.formatter.formatToNgbTimePicker(firstInterval.timeEnd) : this.formatter.formatToNgbTimePicker(1);

    this._startDate.reset();
    this._timeStart.reset();
    this._timeEnd.reset();
  }

  getTimeModel(): TimeModelDto {
    let timeModel: TimeModelDto = TimeModelDto.emptyTimeModelDto();
    timeModel.startDate = this.formatter.formatAsUTCFromNgbDatePicker(this._startDate.value).getTime() / 1000;
    timeModel.intervals = [new TimeModelIntervalDto(
      "",
      this.timeModelId,
      this.formatter.formatFromNgbTimePicker(this._timeStart.value),
      this.formatter.formatFromNgbTimePicker(this._timeEnd.value)
    )];

    return timeModel;
  }


  get startDate(): { year: number; month: number; day: number } {
    return this._startDate.value;
  }

  set startDate(value: { year: number; month: number; day: number }) {
    this._startDate.value = value;
  }

  get timeStart(): { hour: number; minute: number } {
    return this._timeStart.value;
  }

  set timeStart(value: { hour: number; minute: number }) {
    this._timeStart.value = value;
  }

  get timeEnd(): { hour: number; minute: number } {
    return this._timeEnd.value;
  }

  set timeEnd(value: { hour: number; minute: number }) {
    this._timeEnd.value = value;
  }

  get accessReadonly(): boolean {
    return this._accessReadonly;
  }

  set accessReadonly(value: boolean) {
    this._accessReadonly = value;
  }

  get timeStartChanged(): boolean {
    return this._timeStart.hasChanges;
  }

  get timeEndChanged(): boolean {
    return this._timeEnd.hasChanges;
  }

  get startDateChanged(): boolean {
    return this._startDate.hasChanges;
  }
}
