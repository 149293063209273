import { Component, OnInit } from '@angular/core';
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
    standalone: false
})
export class JobsComponent implements OnInit, PendingChangesBlocker {

  // Tabs
  selectedNavTab: 'changes' | 'ongoing' | 'finished' = 'changes'

  constructor() { }

  ngOnInit(): void {
  }

  hasPendingChanges(): boolean {
    return false;
  }

  async onNavigation(event: NgbNavChangeEvent) {
    const currentNav = event.nextId // contains selected element

    this.selectedNavTab = currentNav
  }
}
