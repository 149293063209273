<form [formGroup]="timeModelForm">
  <div class="form-floating form-floating-custom mb-3">
    <date-time-picker style="flex-grow: 1"
                      titleText="TIME_MODELS.FIELD.START_DATE.TEXT"
                      [readonly]="accessReadonly"
                      [(dateValue)]="startDate.value"
                      [invalidDate]="!startDate.isValid"
                      [showChanges]="startDate.hasChanges">
    </date-time-picker>
  </div>

  <!-- REPETITION TYPES -->
  <div class="form-floating form-floating-custom mb-3">
    <select *ngIf="!accessReadonly; else repetitionType" class="form-select" id="repetitionType"
            formControlName="repetitionTypeId" (change)="onRepetitionTypeChange()"
            [ngClass]="{ 'is-invalid': f['repetitionTypeId'] | hasErrors }">
      <option *ngFor="let type of TIME_MODEL_REPETITION_TYPE" [value]="type.id">{{('TIME_MODELS.REPETITION.' + type.value.toUpperCase()) | translate}}</option>
    </select>
    <ng-template #repetitionType>
      <input disabled id="repetitionType" class="form-control" [value]="repetitionTypeTranslationText | translate">
    </ng-template>
    <label for="repetitionType">{{ "TIME_MODELS.FIELD.REPETITION_TYPE.TEXT" | translate }}</label>
    <div class="form-floating-icon">
      <i icon="repetition"></i>
    </div>
  </div>

  <div *ngIf="f['repetitionTypeId']?.value == 4; else repetitionValue" class="form-floating form-floating-custom mb-3">
    <select *ngIf="!accessReadonly; else repetitionMonth" class="form-select" id="repetitionMonth"
            formControlName="repetitionMonth"
            [ngClass]="{ 'is-invalid': f['repetitionMonth'] | hasErrors }">
      <option *ngFor="let type of TIME_MODEL_MONTH_TYPE" [value]="type.id">{{('TIME_MODELS.MONTH.' + type.value.toUpperCase()) | translate}}</option>
    </select>
    <ng-template #repetitionMonth>
      <input disabled id="repetitionMonth" class="form-control" [value]="monthTypeTranslationText | translate">
    </ng-template>
    <label for="repetitionMonth">{{ "TIME_MODELS.FIELD.REPETITION_MONTH.TEXT" | translate }}</label>
    <div class="form-floating-icon">
      <i icon="month"></i>
    </div>
  </div>

  <ng-template #repetitionValue>
    <div class="form-floating form-floating-custom mb-3">
      <input [readonly]="accessReadonly" numberOnly type="number" class="form-control" id="repetitionValue" formControlName="repetitionValue"
             [ngClass]="{ 'is-invalid': f['repetitionValue'] | hasErrors }" min="1" max="999">
      <label for="repetitionValue">{{ "TIME_MODELS.FIELD.REPETITION_VALUE.TEXT" | translate:{repetitionType: repetitionTypeTranslationText | translate } }}</label>
      <div class="form-floating-icon">
        <i icon="interval"></i>
      </div>
      <div *ngIf="f['repetitionValue'] | hasErrors" class="invalid-feedback">
        <div *ngIf="f['repetitionValue'] | hasErrors:'required'">{{ "TIME_MODELS.FIELD.REPETITION_VALUE.REQUIRED" | translate:{repetitionType: repetitionTypeTranslationText | translate } }}</div>
        <div *ngIf="f['repetitionValue'] | hasErrors:'min'">{{ "TIME_MODELS.FIELD.REPETITION_VALUE.MIN" | translate:{repetitionType: repetitionTypeTranslationText | translate } }}</div>
        <div *ngIf="f['repetitionValue'] | hasErrors:'max'">{{ "TIME_MODELS.FIELD.REPETITION_VALUE.MAX" | translate:{repetitionType: repetitionTypeTranslationText | translate } }}</div>
      </div>
    </div>
  </ng-template>

  <!-- EXECUTION REPETITION -->
  <div *ngIf="f['repetitionTypeId']?.value > 2" class="mb-3">
    <div class="d-flex flex-row gap-1">
      <div class="form-floating form-floating-custom flex-grow-1 flex-shrink-0">
        <select *ngIf="!accessReadonly; else executionType" class="form-select" id="executionType"
                formControlName="executionTypeId">
          <option *ngFor="let type of TIME_MODEL_EXECUTION_REPETITION_TYPE" [value]="type.id">{{ ('TIME_MODELS.WEEKDAY.REPETITION_TYPE.' + type.value.toUpperCase()) | translate }}</option>
        </select>
        <ng-template #executionType>
          <input disabled id="executionType" class="form-control" [value]="executionTypeTranslationText | translate">
        </ng-template>
        <label for="executionType">{{ "TIME_MODELS.FIELD.EXECUTION_TYPE.TEXT" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="execution"></i>
        </div>
      </div>

      <div *ngIf="f['executionTypeId']?.value == 1" class="form-floating" style="max-width: 7em;">
        <input [readonly]="accessReadonly" numberOnly type="number" class="form-control" id="executionValue"
               formControlName="executionValue" min="1" max="31">
      </div>
    </div>

    <div *ngIf="f['executionTypeId']?.value == 1 && (f['executionValue'] | hasErrors)"
         class="invalid-feedback d-flex">
      <div *ngIf="f['executionValue'] | hasErrors:'required'">{{ "TIME_MODELS.FIELD.EXECUTION_VALUE.REQUIRED" | translate }}</div>
      <div *ngIf="f['executionValue'] | hasErrors:'min'">{{ "TIME_MODELS.FIELD.EXECUTION_VALUE.MIN" | translate }}</div>
      <div *ngIf="f['executionValue'] | hasErrors:'max'">{{ "TIME_MODELS.FIELD.EXECUTION_VALUE.MAX" | translate }}</div>
    </div>
  </div>

  <div *ngIf="f['repetitionTypeId']?.value == 2 || (f['repetitionTypeId']?.value > 2 && f['executionTypeId']?.value > 1 && f['executionTypeId']?.value <= 6)"
       class="btn-group d-flex mb-3" role="group">
    <ng-container *ngFor="let weekday of weekdays.controls; let i=index">
      <input [readonly]="accessReadonly" type="checkbox" class="btn-check" id="weekday-{{i}}" autocomplete="off"
             [checked]="weekday.value" (change)="setWeekdayActive(weekday, weekday.value)">
      <label for="weekday-{{i}}" class="btn btn-outline-primary mb-0"
             [ngClass]="{'text-muted': !weekday.value, 'btn-light border-light pointer-events-none': accessReadonly}">
        {{getWeekdayById(i+1) | translate}}
      </label>
    </ng-container>
  </div>


  <!-- END TYPES -->
  <div class="form-floating form-floating-custom">
    <select *ngIf="!accessReadonly; else endType" class="form-select" id="endType" formControlName="endTypeId"
            [ngClass]="{ 'is-invalid': f['endTypeId'] | hasErrors }">
      <option *ngFor="let type of TIME_MODEL_END_TYPE" [value]="type.id">{{ ('TIME_MODELS.END.' + type.value.toUpperCase()) | translate }}</option>
    </select>
    <ng-template #endType>
      <input disabled id="endType" class="form-control" [value]="(endTypeTranslationText | translate)">
    </ng-template>
    <label for="endType">{{ "TIME_MODELS.FIELD.END_TYPE.TEXT" | translate }}</label>
    <div class="form-floating-icon">
      <i icon="end-type"></i>
    </div>
  </div>

  <div *ngIf="f['endTypeId']?.value == 2" class="form-floating form-floating-custom mt-3">
    <date-time-picker style="flex-grow: 1"
                      titleText="TIME_MODELS.FIELD.END_VALUE_DATE.TEXT"
                      [readonly]="accessReadonly"
                      [(dateValue)]="endDate.value"
                      [invalidDate]="!endDate.isValid"
                      [showChanges]="endDate.hasChanges">
    </date-time-picker>
  </div>

  <div *ngIf="f['endTypeId']?.value == 3" class="form-floating form-floating-custom mt-3">
    <input [readonly]="accessReadonly" numberOnly type="number" class="form-control" id="endRepetition" formControlName="endRepetition"
           [ngClass]="{ 'is-invalid': f['endRepetition'] | hasErrors }" min="1" max="999">
    <label for="endRepetition">{{ "TIME_MODELS.FIELD.END_VALUE_REPETITION.TEXT" | translate }}</label>
    <div class="form-floating-icon">
      <i icon="repetition"></i>
    </div>
    <div *ngIf="f['endRepetition'] | hasErrors" class="invalid-feedback">
      <div *ngIf="f['endRepetition'] | hasErrors:'required'">{{ "TIME_MODELS.FIELD.END_VALUE_REPETITION.REQUIRED" | translate }}</div>
      <div *ngIf="f['endRepetition'] | hasErrors:'min'">{{ "TIME_MODELS.FIELD.END_VALUE_REPETITION.MIN" | translate }}</div>
      <div *ngIf="f['endRepetition'] | hasErrors:'max'">{{ "TIME_MODELS.FIELD.END_VALUE_REPETITION.MAX" | translate }}</div>
    </div>
  </div>

  @if(!endDate.isValid || !startDate.isValid) {
    <div class="invalid-feedback d-flex">
      <div>{{ "TIME_MODELS.FIELD.END_VALUE_DATE.MINIMUM_DATE" | translate:{unit: "TIME_MODELS.FIELD.END_VALUE_DATE.UNIT." + f['repetitionTypeId'].value | translate} }}</div>
    </div>
  }


</form>
