<div class="sticky-header">
  <app-pagetitle id="pagetitle" title="{{'MENU.USERS' | translate}}" (onSearch)="onSearch($event)"
                 [searchEntries]="searchEntries" [pagetitleActions]="pagetitleActions" (onPagetitleAction)="onPagetitleAction()"
                 [itemSelected]="minimizePagetitle" [disableButtons]="isExpiredLicense"></app-pagetitle>
</div>

<div class="scrollable-content">
  <div class="card card-body mb-2">

    <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified bg-light" [(activeId)]="selectedNavTab"
        (navChange)="onNavigation($event)">
      <li *ngIf="loaded" ngbNavItem="users">
        <a ngbNavLink data-testid="users-tab"><span class="black-align-text">{{ "USERS.TAB.USERS" | translate }}</span></a>
        <ng-template ngbNavContent>
          <app-user-users [onSearch]="searchQuery" (onUpdate)="onUpdate.next()"></app-user-users>
        </ng-template>
      </li>
      <li *ngIf="loaded && isBusinessLicense" ngbNavItem="groups">
        <a ngbNavLink data-testid="users-groups-tab"><span class="black-align-text">{{ "USERS.TAB.USER_GROUPS" | translate }}</span></a>
        <ng-template ngbNavContent>
          <app-user-groups [onSearch]="searchQuery" (onUpdate)="onUpdate.next()"></app-user-groups>
        </ng-template>
      </li>
    </ul>
  </div>

  <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-0"></div>
</div>
