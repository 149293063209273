import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LockingDeviceFormComponent} from './forms/locking-device-form/locking-device-form.component';
import {
  LockingDeviceParamsFormComponent
} from './forms/locking-device-params-form/locking-device-params-form.component';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "../../../core/core.module";
import {LockingDeviceDevicesComponent} from "./locking-device-devices.component";
import {NgbNavModule} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "../../../shared/shared.module";
import {RouterLink} from "@angular/router";

@NgModule({
  declarations: [
    LockingDeviceDevicesComponent,
    LockingDeviceFormComponent,
    LockingDeviceParamsFormComponent
  ],
  exports: [
    LockingDeviceDevicesComponent,
    LockingDeviceParamsFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    CoreModule,
    NgbNavModule,
    SharedModule,
    RouterLink,
    FormsModule
  ]
})
export class LockingDeviceDevicesModule {
}
