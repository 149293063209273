<div class="sticky-header">
  <app-pagetitle
    title="{{ 'MENU.LOGBOOK' | translate }}"
    [disableSearch]="false"
    [searchEntries]="searchIndex"
    (onSearch)="onSearch($event)"
    [itemSelected]="itemSelected"
    [pagetitleActions]="pageActions"
    (onPagetitleAction)="onCreatePdf()"
  ></app-pagetitle>
</div>

<ng-template #reloadFooter>
  <ng-template (isVisible)="onVisibility($event)">
    <p class="text-center">
      <span *ngIf="reachedEnd; else loading">{{
          "LOGBOOK.RELOAD.END" | translate
        }}</span>
      <ng-template #loading>
        <span *ngIf="!isLoading">{{
            "LOGBOOK.RELOAD.SCROLL" | translate
          }}</span>
        <span *ngIf="isLoading">{{
            "LOGBOOK.RELOAD.LOADING" | translate
          }}</span>
      </ng-template>
    </p>
  </ng-template>
</ng-template>

<div class="scrollable-content">
  <app-item-list [loadingCes]="isLoadingPdf"
                 [items]="itemManager"
                 [showLoading]="isLoading"
                 [footerTemplate]="reloadFooter"
                 [multiSelectActions]="multiSelectActions"
                 (onListAction)="onListEvent($event)"
                 [enableReloadItems]="true"
                 (onMultiListAction)="onMultiDeleteEvent($event)"
                 (onItemAction)="onItemDelete($event)"
                 [listFilter]="itemListFilter"
                 [(itemSelected)]="itemSelected"
                 [prefixTitleTranslation]="''"
                 [skeletonShowingAfterMS]="skeletonShowAfterMilliSeconds"
  >
    <div class="row row-cols-lg-6 g-4">
      <div class="col-lg-6">
        <div class="card h-100 shadow-none">
          <h6 class="card-header">{{ "LOGBOOK.SECTION.GENERAL" | translate }}</h6>
          <div class="card-body" *ngIf="selectedItem$ | async">
            <div class="form-floating form-floating-custom mb-3">
              <input disabled type="text" class="form-control"
                   id="title" [value]="logbookTitle | translate">
              <label for="title">{{ "LOGBOOK.FIELD.TITLE.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="name"></i>
              </div>
            </div>

            <div class="form-floating form-floating-custom mb-3">
              <input disabled type="text" class="form-control h-auto" id="description" [value]="logbookSummary">
              <label for="description">{{ "LOGBOOK.FIELD.DESCRIPTION.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="description"></i>
              </div>
            </div>

            <div class="form-floating form-floating-custom">
              <input disabled type="text" class="form-control" id="date" [value]="timestamp | dateTime">
              <label for="date">{{ "LOGBOOK.FIELD.DATE.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="date"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card h-100 shadow-none">
          <h6 class="card-header">{{ "LOGBOOK.SECTION.DETAILS" | translate }}</h6>
          <div class="card-body pb-0">
            <div *ngFor="let detail of logbookDetails; let i = index" class="form-floating form-floating-custom mb-3">
              <input disabled type="text" class="form-control h-auto" [id]="'detail-' + i" [value]="detail.value | translate">
              <label [for]="'detail-' + i" class="text-truncate">
                {{ detail.title | translate }}
              </label>
              <div class="form-floating-icon">
                <i icon="info"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-item-list>
</div>
