import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChangeDetectorForm} from '../../../../../shared/util/change-detector/ChangeDetectorForm';
import {ApiService} from '../../../../../core/services/api-service/api.service';
import {FormatterService} from '../../../../../core/services/formatter-service/formatter.service';
import {LockingDeviceDto} from "../../../../../shared/entities/locking-device/LockingDeviceDto";
import {ASSEMBLY_STATE} from "../../../../../shared/lookup/assembly.lookup";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import {HANDLE_INNER_TYPE, HANDLE_OUTER_TYPE} from "../../../../../shared/lookup/handle.lookup";
import {parseInt} from "lodash";

@Component({
    selector: 'app-locking-device-form',
    templateUrl: './locking-device-form.component.html',
    styleUrls: ['./locking-device-form.component.scss'],
    standalone: false
})
export class LockingDeviceFormComponent implements OnInit {
  @Input() accessReadonly = false;

  _selectedItem?: LockingDeviceDto;
  assemblyDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});

  @Input() set lockingDevice(item: LockingDeviceDto) {
    if (item == null) {
      return;
    }

    this._selectedItem = item;

    this.lockingDeviceForm.patchValue(
      {
        // General
        articleNumber: item.articleNumber,
        description: item.description || '',
        deviceUid: item.deviceUid || 0,
        shortName: item.shortName || '',
        uid: item.deviceUid || '',
        uuid: item.uuid || '',
        // Information
        assemblyState: item.assemblyStateId,
        battery: item.batteryConditionId || 0,
        cylinderDistanceId: `${item.cylinderDistanceId || 0} mm`,
        firmwareVersion: item.firmwareVersion || '',
        technicalId: item.rfidTypeId == 1 ? 'Mifare' : 'LEGIC',
        hardwareVersion: item.hardwareVersion || '',
        handleDirectionId: item.handleDirectionId || 0,
        handleInnerTypeId: this.innerHandleTranslationText(item.handleInnerTypeId || 0),
        handleOuterTypeId: this.outerHandleTranslationText(item.handleOuterTypeId || 0),
        lengthInner: `${item.lengthInner ? item.lengthInner / 10 : 0} mm`,
        lengthOuter: `${item.lengthOuter ? item.lengthOuter / 10 : 0} mm`,
        serialNumber: item.serialNumber,
        squareSizeId: `${item.squareSizeId || 0} mm`,
        // Location
        building: item.locationBuilding || '',
        door: item.locationDoor || '',
        level: item.locationLevel || '',
        room: item.locationRoom || '',
      },
      {emitEvent: false}
    );

    this.assemblyDate = new ChangeDetectorValue(this.formatter.formatToNgbDatePicker(item.assemblyDate), () => {this.onChanges.emit()});

    this.showHardwareVersion = item.hardwareVersion != null;
    this.showLength = this.apiService.lockingDevice.shouldShowLengthForDeviceType(item.deviceTypeId) &&
      this.f['lengthInner'].value != '0 mm';
    this.showHandleInfo = this.apiService.lockingDevice.shouldShowHandleInfoForDeviceType(item.deviceTypeId);
    this.showBatteryIcon = this.apiService.lockingDevice.shouldShowImageForDeviceType(item.deviceTypeId);
    this.batteryImage = this.getBatteryImage();

    this.lockingDeviceForm.markAsUntouched();
    this.changeDetector.refresh();
  }

  @Output() onChanges = new EventEmitter<boolean>();

  // Forms
  lockingDeviceForm: FormGroup;
  changeDetector: ChangeDetectorForm;

  // Values
  batteryImage = '';
  showHardwareVersion = false;
  showLength = false;
  showHandleInfo = false;
  showBatteryIcon = false;

  // Length Validator
  validatorMaxLength64: number = 64;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private formatter: FormatterService
  ) {
    this.lockingDeviceForm = this.formBuilder.group({
      // General
      shortName: [
        '',
        [Validators.required, Validators.maxLength(this.validatorMaxLength64)],
      ],
      description: ['', [Validators.maxLength(this.validatorMaxLength64)]],
      uid: [{value: '', disabled: true}, []],
      uuid: ['', []],
      deviceUid: [-1, []],
      articleNumber: [{value: '', disabled: true}, []],
      // Information
      serialNumber: [{value: '', disabled: true}, []],
      firmwareVersion: [{value: '', disabled: true}, []],
      hardwareVersion: [{value: '', disabled: true}, []],
      technicalId: [{value: '', disabled: true}, []],
      battery: [{value: 0, disabled: true}, []],
      // length
      lengthInner: [{value: '', disabled: true}, []],
      lengthOuter: [{value: '', disabled: true}, []],
      // handle
      handleDirectionId: [0, []],
      handleInnerTypeId: [0, []],
      handleOuterTypeId: [0, []],
      cylinderDistanceId: [{value: 0, disabled: true}, []],
      squareSizeId: [{value: 0, disabled: true}, []],
      // assembly
      assemblyState: [0, []],
      // Location
      building: ['', []],
      level: ['', []],
      room: ['', []],
      door: ['', []],
    });

    this.changeDetector = new ChangeDetectorForm(this.lockingDeviceForm, () => {
      this.onChanges.emit(this.changeDetector.changed);
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.lockingDeviceForm.controls;
  }

  getBatteryImage(): string {
    const imageName: string =
      this.apiService.lockingDevice.getBatteryCondition(
        this._selectedItem?.batteryConditionId || 0
      );
    const themeDesign = document.body.getAttribute('data-bs-theme');
    if (themeDesign != null && themeDesign.includes('black')) {
      return `assets/ces/locking-device/battery/BatteryCondition-${imageName}-black.svg`;
    }
    return `assets/ces/locking-device/battery/BatteryCondition-${imageName}.svg`;
  }

  get changed(): boolean {
    return this.changeDetector.changed || this.assemblyDate.hasChanges;
  }

  get invalid(): boolean {
    return this.lockingDeviceForm.invalid;
  }

  reset() {
    this.lockingDeviceForm.reset();
    this.lockingDeviceForm.markAsUntouched();
    this.changeDetector.refresh();
  }

  getLockingDeviceDtoForCall(): LockingDeviceDto {
    // update values
    this._selectedItem!.shortName =
      this.lockingDeviceForm.get('shortName')?.value;
    this._selectedItem!.description =
      this.lockingDeviceForm.get('description')?.value;
    this._selectedItem!.assemblyStateId = parseInt(this.lockingDeviceForm.get('assemblyState')?.value);
    this._selectedItem!.assemblyDate = this.formatter.formatAsUTCFromNgbDatePicker(this.assemblyDate.value).getTime() / 1000;
    this._selectedItem!.locationBuilding =
      this.lockingDeviceForm.get('building')?.value;
    this._selectedItem!.locationLevel =
      this.lockingDeviceForm.get('level')?.value;
    this._selectedItem!.locationRoom =
      this.lockingDeviceForm.get('room')?.value;
    this._selectedItem!.locationDoor =
      this.lockingDeviceForm.get('door')?.value;

    return this._selectedItem!;
  }

  get assemblyStateTranslationText(): string {
    return `LOCKING_DEVICES.ASSEMBLY_STATE.${[...ASSEMBLY_STATE.filter(value => `${this.lockingDeviceForm.get('assemblyState')?.value}` == `${value.id}`),ASSEMBLY_STATE[0]][0].value.toUpperCase()}`;
  }

  private innerHandleTranslationText(id: number): string {
    let translation: string = '';
    HANDLE_INNER_TYPE.filter(value => value.id == id).forEach(value => {
      translation = `LOCKING_DEVICES.HANDLE.OUTER_TYPE_${value.value.toUpperCase()}`;
    });
    return translation;
  }

  private outerHandleTranslationText(id: number): string {
    let translation: string = '';
    HANDLE_OUTER_TYPE.filter(value => value.id == id).forEach(value => {
      translation = `LOCKING_DEVICES.HANDLE.OUTER_TYPE_${value.value.toUpperCase()}`;
    });
    return translation;
  }

  protected readonly ASSEMBLY_STATE = ASSEMBLY_STATE;
}
