import {Component, ViewChild} from '@angular/core';
import {CommissioningComponent} from "../../../../shared/commissioning/commissioning.component";
import {CommissionManager} from "../../../../core/services/auth-service/support-services/CommissionManager";
import {BindingProcessError} from "../../../../shared/entities/IOB/BindingProcessError";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "../../../../shared/notification/modal/modal.service";
import {debounceTime} from "rxjs";
import {ModalContainerContent} from "../../../../shared/notification/modal/modal-container.class";
import {ModalCloseCause} from "../../../../core/enums/modalCloseCause";
import { ItemAction } from 'src/app/shared/models/Actions';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';
import {ApiService} from "../../../../core/services/api-service/api.service";

@Component({
    selector: 'app-locking-media-unbinding',
    templateUrl: './locking-media-unbinding.component.html',
    styleUrl: './locking-media-unbinding.component.scss',
    standalone: false
})
export class LockingMediaUnbindingComponent {
  @ViewChild(CommissioningComponent) Commissioning!: CommissioningComponent;

  private _commissionManager: CommissionManager = CommissionManager.getInstance(IoBridgeFlow.MEDIA_UNBIND);

  isLoading: boolean = false;
  globalBindingErrorProcess: BindingProcessError;

  providePicc: string = '';
  provideSN: string = '';
  objectName: string = '';
  processAndPollingRunning: boolean = false;

  headerText: string = 'PROGRAMMING.MODAL.LOCKING_MEDIA.REMOVE';
  buttonText: string = 'PROGRAMMING.MODAL.BUTTON.CLOSE_DETAIL';

  addRemoveButton: ItemAction = new ItemAction (
    'PROGRAMMING.MODAL.LOCKING_MEDIA.REMOVE',
    (): void => {this.startProcessIOBridge()},
    false,
    'btn btn-outline-danger',
    ''
  )

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private alertService: ModalService,
  ){
    this.globalBindingErrorProcess = this.commissionManager.bindingProcessError;
    this.commissionManager.bindingProcessErrorObservable.pipe(debounceTime(500)).subscribe({
      next: value => this.globalBindingErrorProcess = value
    });
  }

  ngOnInit(): void {
    this.providePicc = this.commissionManager.bindingProcess.pin;
    this.provideSN = this.commissionManager.bindingProcess.serialNumber;
    this.objectName = this.commissionManager.bindingProcess.objectName;

    if (!this.globalBindingErrorProcess.blockTillReset) {
      this.isLoading = true;
      this.apiService.iobService.iobConnectionUpdater().then(() => {
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.commissionManager.bindingProcessState.order == 0 && !this.globalBindingErrorProcess.blockTillReset) {
      this.commissionManager.reset();
    }
    this.apiService.iobService.iobConnectionUpdater();
  }

  async startProcessIOBridge() {
    this.Commissioning.startButtonClicked = true;
    this.unbind()
    this.Commissioning.startButtonClicked = false;
  }

  private async unbind() {
    const modalContent: ModalContainerContent = new ModalContainerContent(
      this.translate.instant("LOCKING_MEDIA.ITEM_LIST.UNBIND.TITLE"),
      this.translate.instant('LOCKING_MEDIA.ITEM_LIST.UNBIND.MESSAGE', {name: this.commissionManager.bindingProcess.serialNumber})
    );
    if (await this.alertService.openModal(modalContent)) {
      await this.Commissioning.prepare();
      this.Commissioning.pollFetch().catch(() => this.processAndPollingRunning = false);
      const unbind: Response = await this.apiService.iobService.fetchUnbindingMedia(this.commissionManager.bindingProcess.objectUuid!);
      if (unbind.ok) {
        await this.Commissioning.finishSuccessAndClose(ModalCloseCause.SUCCESS || 3);
      } else {
        await this.Commissioning.finishError(unbind);
      }
    }
  }


  //

  get commissionManager(): CommissionManager {
    return this._commissionManager;
  }

  get pinInputEnabled(): boolean {
    return !(this.commissionManager.bindingProcessState.order! != 0) && !this.commissionManager.errorIsPresent;
  }

}
