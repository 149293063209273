<app-item-list [items]="itemManager" [showLoading]="isLoading" [viewTypeModuleId]="'16'"
               (onListAction)="onListEvent($event)">


  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "JOBS.SECTION.GENERAL" | translate }}</h6>
        <div class="card-body">

          <div class="d-flex gap-1 mb-3">
            <div class="form-floating form-floating-custom flex-grow-1">
              <input disabled type="text" class="form-control" id="job-id" [value]="selectedItem?.['id']">
              <label for="job-id" class="text-truncate">{{ "JOBS.FIELD.JOB_ID.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="id"></i>
              </div>
            </div>

            <div class="form-floating flex-grow-1">
              <input disabled type="text" class="form-control" id="deviceUid" [value]="selectedItem?.['lockingDeviceUid']">
              <label for="deviceUid" class="text-truncate">{{ "JOBS.FIELD.DEVICE_UID.TEXT" | translate }}</label>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input disabled type="text" class="form-control" id="deviceName" [value]="selectedItem?.['shortName']">
            <label for="deviceName">{{ "JOBS.FIELD.DEVICE_NAME.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <div disabled type="text" class="form-control h-auto" id="programmingState">
              {{programmingStateTranslationText | translate}}
            </div>
            <label for="programmingState">{{ "JOBS.FIELD.PROGRAMMING_STATE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i [icon]="selectedItem?.['stateId'] == 2 ? 'check' : 'cross'"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input disabled type="text" class="form-control" id="createdUser" [value]="selectedItem?.['createdUsername']">
            <label for="createdUser">{{ "JOBS.FIELD.CREATED_USER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="user"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input disabled type="text" class="form-control" id="createdDate" [value]=" selectedItem?.['createdTimestamp']! | dateTime">
            <label for="createdDate">{{ "JOBS.FIELD.CREATED_DATE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="date"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input disabled type="text" class="form-control" id="finishedDate" [value]=" selectedItem?.['finishedTimestamp']! | dateTime">
            <label for="finishedDate">{{ "JOBS.FIELD.FINISHED_DATE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="date"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <app-jobs-distributed-device-list [distributedDevices]="currentDistributedDevices"></app-jobs-distributed-device-list>

  </div>

</app-item-list>
