import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

import {ITimeModelDynamicForm} from "../ITimeModelDynamicForm";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChangeDetectorForm} from "../../../../shared/util/change-detector/ChangeDetectorForm";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {FormValidator} from "../../../../shared/util/FormValidator";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";
import {TimeModelExecutionRepetitionDto} from "../../../../shared/entities/TimeModel/TimeModelExecutionRepetitionDto";
import {
  TIME_MODEL_END_TYPE, TIME_MODEL_EXECUTION_REPETITION_TYPE,
  TIME_MODEL_MONTH_TYPE,
  TIME_MODEL_REPETITION_TYPE,
  TIME_MODEL_WEEKDAY_TYPE
} from "../../../../shared/lookup/timemodel.lookup";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
    selector: 'app-time-model-repetition-form',
    templateUrl: './time-model-repetition-form.component.html',
    styleUrls: ['./time-model-repetition-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
//TODO Check if changeDetection: ChangeDetectionStrategy.OnPush is necessary
export class TimeModelRepetitionFormComponent implements OnInit, ITimeModelDynamicForm {
  readonly intervalEnabled = true;
  readonly customFormEnabled = true;
  accessReadonly: boolean = false;

  startDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});
  endDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});

  get dynamicForm(): AbstractControl | null {
    return this.timeModelForm;
  }

  timeModelForm: FormGroup
  changeDetector: ChangeDetectorForm
  initialExecutionRepetition?: TimeModelExecutionRepetitionDto[]

  constructor(
    private formBuilder: FormBuilder,
    private formatter: FormatterService) {
    this.timeModelForm = formBuilder.group({
      endTypeId: [0, []],
      endRepetition: [0, [Validators.required]],
      repetitionTypeId: [0, []],
      repetitionValue: [0, [Validators.required]],
      repetitionMonth: [0, []],
      executionTypeId: [0, []],
      executionValue: [0, [Validators.required]],
      executionWeekdays: formBuilder.array([false, false, false, false, false, false, false], [])
    });
    this.timeModelForm.markAllAsTouched()
    this.changeDetector = new ChangeDetectorForm(this.timeModelForm, () => {
      this.onChanges()
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.timeModelForm.controls;
  }

  get weekdays() {
    return this.timeModelForm.get('executionWeekdays') as FormArray;
  }

  initialize(timeModel: TimeModelDto, accessReadonly: boolean): void {
    this.accessReadonly = accessReadonly

    if (timeModel == null) return;

    this.startDate = new ChangeDetectorValue(this.formatter.formatToNgbDatePicker(timeModel.startDate), () => {this.onChanges()},
      new Map().set('startDateBeforeEndDate', (input: any) => {
        return this.f['endTypeId']?.value != '2' || Date.UTC(this.startDate.value.year, this.startDate.value.month-1, this.startDate.value.day+1).valueOf()-1 < Date.UTC(this.endDate.value.year, this.endDate.value.month-1, this.endDate.value.day).valueOf()
      }));
    this.endDate = new ChangeDetectorValue(this.formatter.formatToNgbDatePicker((timeModel.endTypeId == 2 && timeModel.endValue != null) ?
      timeModel.endValue : this.formatter.addDate(this.formatter.formatFromNgbDatePicker(this.startDate.value), 1)), () => {this.onChanges()} ,
      new Map().set('endDateAfterStartDate', (input: any) => {
        return this.f['endTypeId']?.value != '2' ||Date.UTC(this.startDate.value.year, this.startDate.value.month, this.startDate.value.day+1).valueOf()-1 < Date.UTC(this.endDate.value.year, this.endDate.value.month, this.endDate.value.day).valueOf()
      }));

    const repetitionTypeId = timeModel.repetitionTypeId || 1
    const repetitionValue = timeModel.repetitionValue || 1

    this.initialExecutionRepetition = (timeModel.executionRepetition || []) as TimeModelExecutionRepetitionDto[];
    let executionTypeId = this.initialExecutionRepetition?.[0]?.executionTypeId || 1
    if (executionTypeId > 7) {
      executionTypeId = 1
    }
    const executionValue = executionTypeId == 1 ?
      this.formatter.limit(this.initialExecutionRepetition?.[0]?.executionValue, 1, 31, 1) : 1
    let executionWeekdays = [false, false, false, false, false, false, false]

    if (repetitionTypeId == 2 || (executionTypeId > 1 && executionTypeId <= 6)) {

      this.initialExecutionRepetition?.forEach((repetition) => {
        let executionWeekdayId = repetition.executionWeekdayId
        if (executionWeekdayId != null && executionWeekdayId >= 1 && executionWeekdayId <= 7) {
          executionWeekdays[executionWeekdayId - 1] = true
        }
      })
    }
    if (!executionWeekdays.includes(true)) {
      executionWeekdays[0] = true
    }

    this.timeModelForm.patchValue({
      endTypeId: `${timeModel.endTypeId || 1}`,
      endRepetition: timeModel.endTypeId == 3 ? timeModel.endValue : 1,
      repetitionTypeId: `${repetitionTypeId}`,
      repetitionValue: repetitionTypeId != 4 ? repetitionValue : 1,
      repetitionMonth: `${repetitionTypeId == 4 ? repetitionValue : 1}`,
      executionTypeId: `${executionTypeId}`,
      executionValue: executionValue
    })

    this.timeModelForm.setControl('executionWeekdays', this.formBuilder.array(executionWeekdays, []));

    this.changeDetector.refresh()
  }

  getTimeModel(): TimeModelDto {
    let timeModel: TimeModelDto = TimeModelDto.emptyTimeModelDto();

    // Start/End
    timeModel.startDate = this.formatter.formatAsUTCFromNgbDatePicker(this.startDate.value).getTime() / 1000;
    timeModel.endTypeId = parseInt(this.timeModelForm.get('endTypeId')?.value)

    if (timeModel.endTypeId == 2) {
      timeModel.endValue = this.formatter.formatAsUTCFromNgbDatePicker(this.endDate.value).getTime() / 1000;
    } else if (timeModel.endTypeId == 3) {
      timeModel.endValue = parseInt(this.timeModelForm.get('endRepetition')?.value)
    }

    // Repetition
    timeModel.repetitionTypeId = parseInt(this.timeModelForm.get('repetitionTypeId')?.value)
    timeModel.repetitionValue = timeModel.repetitionTypeId == 4 ?
      parseInt(this.timeModelForm.get('repetitionMonth')?.value) :
      parseInt(this.timeModelForm.get('repetitionValue')?.value)

    // Execution
    if (timeModel.repetitionTypeId == 1) {
      return timeModel // Type day has no executionRepetition
    }

    const executionRepetition: TimeModelExecutionRepetitionDto[] = []
    const executionTypeId = timeModel.repetitionTypeId == 2 ?
      undefined : parseInt(this.timeModelForm.get('executionTypeId')?.value)
    const executionWeekdays: boolean[] = (this.timeModelForm.get('executionWeekdays') as FormArray).getRawValue()

    if (executionTypeId == 1) {
      const execution = new TimeModelExecutionRepetitionDto(
        this.initialExecutionRepetition?.[0]?.uuid || "",
        this.initialExecutionRepetition?.[0]?.id || -1,
        executionTypeId,
        undefined,
        parseInt(this.timeModelForm.get('executionValue')?.value),
      );
      executionRepetition.push(execution)
    }
    if (executionTypeId == undefined || (executionTypeId > 1 && executionTypeId <= 6)) {
      executionWeekdays.forEach((value, index) => {
        if (value) {
          const execution = new TimeModelExecutionRepetitionDto(
            this.initialExecutionRepetition?.[executionRepetition.length]?.uuid || "",
            this.initialExecutionRepetition?.[executionRepetition.length]?.id || -1,
            executionTypeId,
            index + 1,
            undefined,
          );
          executionRepetition.push(execution)
        }
      })
    }
    if (executionTypeId == 7) {
      const execution = new TimeModelExecutionRepetitionDto(
        this.initialExecutionRepetition?.[0]?.uuid || "",
        this.initialExecutionRepetition?.[0]?.id || -1,
        executionTypeId,
        undefined,
        undefined
      )
      executionRepetition.push(execution)
    }

    timeModel.executionRepetition = executionRepetition;

    return timeModel;
  }

  get invalid(): boolean {
    return this.timeModelForm.invalid || !this.endDate.isValid || !this.startDate.isValid;
  }

  onChanges(): void {
  }

  get changed(): boolean {
    return this.changeDetector.changed || this.startDate.hasChanges || (this.endDate.hasChanges && this.timeModelForm.get('endTypeId')?.value == 2);
  }

  onRepetitionTypeChange() {
    if (this.timeModelForm.get('repetitionTypeId')?.value != 3 && this.timeModelForm.get('repetitionTypeId')?.value != 4) {
      return;
    }

    const weekdays = this.timeModelForm.get('executionWeekdays') as FormArray
    const selectedControl = weekdays.controls.find(control => control.value == true)

    if (selectedControl != null) {
      this.setWeekdayActive(selectedControl, false)
    }
  }

  setWeekdayActive(weekday: AbstractControl, value: boolean) {
    if (this.accessReadonly) {
      return;
    }

    weekday.patchValue(!value)

    if (this.timeModelForm.get('repetitionTypeId')?.value == 2) {
      return;
    }

    const weekdays = this.timeModelForm.get('executionWeekdays') as FormArray
    for (let control of weekdays.controls) {
      if (weekday != control) {
        control.patchValue(false)
      }
    }
  }

  get repetitionTypeTranslationText(): string {
    return `TIME_MODELS.REPETITION.${[...TIME_MODEL_REPETITION_TYPE.filter(value => this.timeModelForm.get('repetitionTypeId')?.value == `${value.id}`),TIME_MODEL_REPETITION_TYPE[0]][0].value.toUpperCase()}`;
  }

  get executionTypeTranslationText(): string {
    return `TIME_MODELS.REPETITION.${[...TIME_MODEL_REPETITION_TYPE.filter(value => this.timeModelForm.get('executionTypeId')?.value == `${value.id}`),TIME_MODEL_REPETITION_TYPE[0]][0].value.toUpperCase()}`;
  }

  get monthTypeTranslationText(): string {
    return `TIME_MODELS.MONTH.${[...TIME_MODEL_MONTH_TYPE.filter(value => this.timeModelForm.get('repetitionMonth')?.value == `${value.id}`),TIME_MODEL_MONTH_TYPE[0]][0].value.toUpperCase()}`;
  }

  get endTypeTranslationText(): string {
    return `TIME_MODELS.END.${[...TIME_MODEL_END_TYPE.filter(value => this.timeModelForm.get('endTypeId')?.value == `${value.id}`),TIME_MODEL_END_TYPE[0]][0].value.toUpperCase()}`;
  }

  getWeekdayById(id: number): string {
    return `TIME_MODELS.WEEKDAY.${[...TIME_MODEL_WEEKDAY_TYPE.filter(value => value.id == id),TIME_MODEL_WEEKDAY_TYPE[0]][0].value.toUpperCase()}`;
  }

  protected readonly TIME_MODEL_REPETITION_TYPE = TIME_MODEL_REPETITION_TYPE;
  protected readonly TIME_MODEL_MONTH_TYPE = TIME_MODEL_MONTH_TYPE;
  protected readonly TIME_MODEL_WEEKDAY_TYPE = TIME_MODEL_WEEKDAY_TYPE;
  protected readonly TIME_MODEL_END_TYPE = TIME_MODEL_END_TYPE;
  protected readonly TIME_MODEL_EXECUTION_REPETITION_TYPE = TIME_MODEL_EXECUTION_REPETITION_TYPE;
}
