<div class="sticky-header">
  <app-pagetitle title="{{'MENU.LOCKING_MEDIA' | translate}}" (onSearch)="onSearch($event)"
                 [searchEntries]="searchEntries"
                 [disableButtons]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                 [itemSelected]="itemSelected">
    <div *ngIf="canAddOrRemoveMedia" ngbDropdown class="d-flex">
      <button data-testid="addMedia" id="addMedia" [disabled]="!ioBridgeConnected"
              type="button" class="btn btn-outline-primary" (click)="onAddMedia()">
        {{ "BUTTON.ADD" | translate }}
      </button>
    </div>
  </app-pagetitle>
</div>

<div class="scrollable-content">
  <app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
                 (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)"
                 [disableButtons]="licenseTypeId > 1 && !licenseIsValidForBusiness"
                 [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'6'">
    @if (media.value != undefined) {
      <form>
        <fieldset [disabled]="selectedItem?.stateId == 3 || selectedItem?.stateId == 4">
          <div class="row row-cols-lg-6 g-4" [class.row-cols-lg-6]="(selectedItem?.mediaNumber || 0) < 100000">
            <div [class.col-lg-6]="(selectedItem?.mediaNumber || 0) < 100000">
              <div class="card h-100 shadow-none">
                <h6 class="card-header">{{ "LOCKING_MEDIA.SECTION.GENERAL" | translate }}</h6>
                <div class="card-body">

                  <div class="form-floating form-floating-custom mb-3">
                    <input type="text"
                           class="form-control"
                           id="displayUid"
                           name="displayUid"
                           [ngClass]="{
                                            'is-invalid': media | hasChangeErrors:'displayUid',
                                            'pending-changes' : media.hasSpecificChanges('displayUid') && !(media | hasChangeErrors:'displayUid')
                                            }"
                           [(ngModel)]="media.value.displayUid"
                           placeholder="-"
                           [readOnly]="accessReadonly"
                           [disabled]="(selectedItem?.mediaNumber || 0) >= 100000">
                    <label for="displayUid">{{ "LOCKING_MEDIA.FIELD.DISPLAY_UID.TEXT" | translate }}</label>
                    <div class="form-floating-icon">
                      <i icon="id"></i>
                    </div>
                    <div *ngIf="media | hasChangeErrors:'displayUid'" class="invalid-feedback">
                      <div >{{ "LOCKING_MEDIA.FIELD.DISPLAY_UID.INVALID" | translate }}</div>
                    </div>
                  </div>

                  <div class="form-floating form-floating-custom mb-3">
                    <input [readonly]="accessReadonly"
                           type="text"
                           class="form-control"
                           id="mechanicalId"
                           name="mechanicalId"
                           maxlength="32"
                           [(ngModel)]="media.value.mechanicalId"
                           [ngClass]="{
                                        'is-invalid': !media.isSpecificGroupValid('mechanicalId'),
                                        'pending-changes' : media.hasSpecificChanges('mechanicalId') && media.isSpecificGroupValid('mechanicalId')
                                        }" placeholder="-">
                    <label for="mechanicalId">{{ "LOCKING_MEDIA.FIELD.MECHANICAL_ID.TEXT" | translate }}</label>
                    <div class="form-floating-icon">
                      <i icon="name"></i>
                    </div>
                    <div *ngIf="media | hasChangeErrors:'mechanicalId':'Max'" class="invalid-feedback">
                      <div >{{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_MEDIA.FIELD.MECHANICAL_ID.TEXT" | translate) : ('31') }}</div>
                    </div>
                  </div>

                  <div *ngIf="showArticleNumber" class="form-floating form-floating-custom mb-3">
                    <input disabled
                           type="text"
                           class="form-control"
                           id="articleNumber"
                           name="articleNumber"
                           [ngModel]="media.value.articleNumber"
                           placeholder="-">
                    <label for="articleNumber">{{ "LOCKING_MEDIA.FIELD.ARTICLE_NUMBER.TEXT" | translate }}</label>
                    <div class="form-floating-icon">
                      <i icon="reference"></i>
                    </div>
                  </div>

                  <div class="form-floating form-floating-custom mb-3">
                    <input disabled
                           type="text"
                           class="form-control"
                           id="lockingMediaType"
                           name="lockingMediaType"
                           [ngModel]="media.value.typeId"
                           placeholder="-">
                    <label for="lockingMediaType">{{ "LOCKING_MEDIA.FIELD.LOCKING_MEDIA_TYPE.TEXT" | translate }}</label>
                    <div class="form-floating-icon">
                      <i icon="technical"></i>
                    </div>
                  </div>

                  <div class="form-floating form-floating-custom mb-3">
                    <input disabled
                           type="text"
                           class="form-control"
                           id="implementationType"
                           name="implementationType"
                           [ngModel]="media.value.implementationType"
                           placeholder="-">
                    <label for="implementationType">{{ "LOCKING_MEDIA.FIELD.IMPLEMENTATION_TYPE.TEXT" | translate }}</label>
                    <div class="form-floating-icon">
                      <i icon="technical"></i>
                    </div>
                  </div>

                  <div class="form-floating form-floating-custom">
                    <input disabled
                           type="text"
                           class="form-control"
                           id="assignedUsers"
                           name="assignedUsers"
                           [ngModel]="media.value.assignedUsers"
                           placeholder="-">
                    <label for="assignedUsers">{{ "LOCKING_MEDIA.FIELD.ASSIGNED_USERS.TEXT" | translate }}</label>
                    <div class="form-floating-icon">
                      <i icon="users"></i>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            @if((selectedItem?.mediaNumber || 0) < 100000){
              <div class="col-lg-6">
                <div class="card h-100 shadow-none">
                  <h6 class="card-header">{{ "LOCKING_MEDIA.SECTION.STATE" | translate }}</h6>
                  <div class="card-body">

                    <div class="form-floating form-floating-custom mb-3">
                      <select *ngIf="!accessReadonly; else state"
                              class="form-select"
                              [ngClass]="{ 'pending-changes' : media.hasSpecificChanges('stateId') }"
                              id="state"
                              [(ngModel)]="media.value.stateId"
                              name="state">
                        <option *ngFor="let state of MEDIA_STATE"
                                [ngValue]="state.id">{{ ('LOCKING_MEDIA.STATE.' + state.value.toUpperCase()) | translate }}
                        </option>
                      </select>
                      <ng-template #state>
                        <input disabled id="state" class="form-control" [value]="stateTranslationText | translate">
                      </ng-template>
                      <label for="state">{{ "LOCKING_MEDIA.FIELD.STATE.TEXT" | translate }}</label>
                      <div class="form-floating-icon">
                        <i icon="select"></i>
                      </div>
                    </div>

                    <div class="form-floating form-floating-custom mb-3">
                      <date-time-picker style="flex-grow: 1"
                                        titleText="LOCKING_MEDIA.FIELD.HANDOUT_DATE.TEXT"
                                        [clearable]="true"
                                        [readonly]="accessReadonly"
                                        [(dateValue)]="media.value.dateHandout"
                                        [showChanges]="media.hasSpecificChanges('dateHandout')">
                      </date-time-picker>
                    </div>
                    <div class="form-floating form-floating-custom">
                      <date-time-picker style="flex-grow: 1"
                                        titleText="LOCKING_MEDIA.FIELD.RETURN_DATE.TEXT"
                                        [clearable]="true"
                                        [readonly]="accessReadonly"
                                        [(dateValue)]="media.value.dateReturn"
                                        [invalidDate]="!media.isSpecificGroupValid('dateReturn')"
                                        [showChanges]="media.hasSpecificChanges('dateReturn')">
                      </date-time-picker>
                    </div>

                    <div *ngIf="!media.isSpecificGroupValid('dateReturn')" class="d-flex invalid-feedback">
                      <div>{{ "LOCKING_MEDIA.FIELD.RETURN_DATE.GREATER_THAN" | translate }}</div>
                    </div>

                  </div>
                </div>
              </div>
            }
          </div>
        </fieldset>
      </form><!-- end form -->
    }
  </app-item-list>
</div>
