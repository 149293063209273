<app-item-list [items]="itemManager" [showLoading]="isLoading"
               (onListAction)="onListEvent($event)"
               (onItemReturn)="onReturnEvent()" [viewTypeModuleId]="'15'">


  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "JOBS.SECTION.GENERAL" | translate }}</h6>
        <div class="card-body">

          <div class="d-flex gap-1 mb-3">
            <div class="form-floating form-floating-custom flex-grow-1">
              <input disabled type="text" class="form-control" id="job-id" [value]="selectedItem?.['id']">
              <label for="job-id" class="text-truncate">{{ "JOBS.FIELD.JOB_ID.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="id"></i>
              </div>
            </div>

            <div class="form-floating flex-grow-1">
              <input disabled type="text" class="form-control" id="deviceUid" [value]="selectedItem?.['lockingDeviceUid']">
              <label for="deviceUid" class="text-truncate">{{ "JOBS.FIELD.DEVICE_UID.TEXT" | translate }}</label>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input disabled type="text" class="form-control" id="deviceName" [value]="selectedItem?.['shortName']">
            <label for="deviceName">{{ "JOBS.FIELD.DEVICE_NAME.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input disabled type="text" class="form-control" id="createdUser" [value]="selectedItem?.['createdUsername']">
            <label for="createdUser">{{ "JOBS.FIELD.CREATED_USER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="user"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input disabled type="text" class="form-control" id="createdDate" [value]="selectedItem?.['createdTimestamp']! | dateTime">
            <label for="createdDate">{{ "JOBS.FIELD.CREATED_DATE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="date"></i>
            </div>
          </div>

          <div *ngIf="selectedItem?.['lastReceivedEventTimestamp'] != undefined" class="form-floating form-floating-custom mt-3">
            <input disabled type="text" class="form-control" id="lastEventDate" [value]="selectedItem?.['lastReceivedEventTimestamp']! | dateTime">
            <label for="lastEventDate">{{ "JOBS.FIELD.LAST_RECEIVED_EVENT_DATE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="date"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "JOBS.SECTION.STATE" | translate }}</h6>
        <div class="card-body">

          <ngb-progressbar class="w-100 mb-1" type="success" height="1rem" [value]="selectedItem?.['completedJournals'] || 0"
                           [max]="selectedItem?.['totalJournals'] || 0" >
            <span class="fw-bold">{{ "JOBS.FIELD.PROGRESS" | translate:{value: selectedItem?.['totalJournals'] || 0, max:selectedItem?.['completedJournals'] || 0} }}</span>
          </ngb-progressbar>

          <div class="d-flex gap-1 spacer-s">
            <div class="form-floating form-floating-custom flex-grow-1 text-truncate">
              <input disabled type="text" class="form-control" id="packages-processed" [value]="selectedItem?.['completedJournals']">
              <label for="packages-processed">{{ "JOBS.FIELD.PACKAGES_PROCESSED.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="info"></i>
              </div>
            </div>

            <div class="form-floating flex-grow-1 text-truncate">
              <input disabled type="text" class="form-control" id="packages-total" [value]="selectedItem?.['totalJournals']">
              <label for="packages-total">{{ "JOBS.FIELD.PACKAGES_TOTAL.TEXT" | translate }}</label>
            </div>
          </div>

        </div>
      </div>
    </div>

    <app-jobs-distributed-device-list *ngIf="currentDistributedDevices != null && currentDistributedDevices.length != 0"
                                      [distributedDevices]="currentDistributedDevices" [dynamicList]="true"></app-jobs-distributed-device-list>

  </div>

</app-item-list>
