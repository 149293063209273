<form>
  <div class="row row-cols-lg-6 g-4">

    <div [classList]="'col-lg-6'">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SETTINGS.SECURITY" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3 switch-new-design-black">
            <div id="formSelfRegistration" class="form-control settings-switch"
                 [ngClass]="{'pending-changes': system.hasSpecificChanges('selfRegistration')}">
              <div class="form-check form-switch form-switch-md">
                <input type="checkbox" class="form-check-input" id="selfRegistration"
                       name="selfRegistration" [(ngModel)]="system.value.selfRegistration">
              </div>
            </div>
            <label for="selfRegistration">{{ "SYSTEM.SETTINGS.SELF_REGISTRATION" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="security"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <select class="form-select" id="passwordComplexity" name="passwordComplexity"
                    [ngClass]=" {'pending-changes': system.hasSpecificChanges('passwordComplexity')}"
                    [(ngModel)]="system.value.passwordComplexity">
              <option *ngFor="let option of passwordComplexityData"
                      [value]="option.id">{{ "SYSTEM.SETTINGS.PASSWORD_COMPLEXITY_" + option.label | translate }}
              </option>
            </select>
            <ng-template #passwordComplexity>
              <input disabled id="passwordComplexityInput" class="form-control" [value]="passwordComplexity">
            </ng-template>
            <label for="passwordComplexityInput">{{ "SYSTEM.SETTINGS.PASSWORD_COMPLEXITY" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="password"></i>
            </div>
            <div class="password-complexity-explanation invalid-feedback">
              <div>
                @switch (parseInt(system.value.passwordComplexity)) {
                  @case (1) {
                    {{ "AUTH.FIELD.NEW_PASSWORD.FORM.DESCRIPTION_WEAK" | translate }}
                  }
                  @case (2) {
                    {{ "AUTH.FIELD.NEW_PASSWORD.FORM.DESCRIPTION_MIDDLE" | translate }}
                  }
                  @case (3) {
                    {{ "AUTH.FIELD.NEW_PASSWORD.FORM.DESCRIPTION_STRONG" | translate }}
                  }
                }
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3 switch-new-design-black">
            <div id="formNewUserPasswordChange" class="form-control settings-switch"
                 [ngClass]=" {'pending-changes': system.hasSpecificChanges('newUserPasswordChange')}">
              <div class="form-check form-switch form-switch-md">
                <input type="checkbox" class="form-check-input" id="newUserPasswordChange"
                       name="newUserPasswordChange" [(ngModel)]="system.value.newUserPasswordChange">
              </div>
            </div>
            <label
              for="newUserPasswordChange">{{ "SYSTEM.SETTINGS.ENFORCE_PASSWORD_CHANGE_FOR_NEW_USER" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="password"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input numberOnly type="number" class="form-control" id="mobilePermissionDuration"
                   name="mobilePermissionDuration" [(ngModel)]="system.value.mobilePermissionDuration"
                   [ngClass]="{'pending-changes': system.hasSpecificChanges('mobilePermissionDuration') && system.isSpecificValid('mobilePermissionDurationMin', 'mobilePermissionDurationMax', 'mobilePermissionDurationRequired') ,'is-invalid': !system.isSpecificValid('mobilePermissionDurationMin', 'mobilePermissionDurationMax', 'mobilePermissionDurationRequired') }"
                   min="8" max="48">
            <label for="mobilePermissionDuration">{{ "SYSTEM.SETTINGS.MOBILE_PERMISSION_DURATION" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="interval"></i>
            </div>
            @if (!system.isSpecificValid('mobilePermissionDurationMin', 'mobilePermissionDurationMax', 'mobilePermissionDurationRequired')) {
              <div class="invalid-feedback">
                <div>
                  @if (!system.isSpecificValid('mobilePermissionDurationRequired')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.MOBILE_PERMISSION.REQUIRED" | translate }}
                  } @else if (!system.isSpecificValid('mobilePermissionDurationMin')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.MOBILE_PERMISSION.MIN" | translate }}
                  } @else if (!system.isSpecificValid('mobilePermissionDurationMax')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.MOBILE_PERMISSION.MAX" | translate }}
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div [classList]="'col-lg-6'">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SETTINGS.MAINTENANCE" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3">
            <input numberOnly type="number" class="form-control" id="mailDeleteAfterDays" name="mailDeleteAfterDays"
                   [(ngModel)]="system.value.mailDeleteAfterDays"
                   [ngClass]="{ 'pending-changes': system.isSpecificValid('mailDeleteAfterDaysMin', 'mailDeleteAfterDaysMax', 'mailDeleteAfterDaysRequired') && system.hasSpecificChanges('mailDeleteAfterDays') ,'is-invalid': !system.isSpecificValid('mailDeleteAfterDaysMin', 'mailDeleteAfterDaysMax', 'mailDeleteAfterDaysRequired')}"
                   min="1" max="5">
            <label
              for="mailDeleteAfterDays">{{ "SYSTEM.SETTINGS.TIME_TILL_DELETE_UNCONFIRMED_EMAILS" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="interval"></i>
            </div>
            @if (!system.isSpecificValid('mailDeleteAfterDaysMin', 'mailDeleteAfterDaysMax', 'mailDeleteAfterDaysRequired')) {
              <div class="invalid-feedback">
                <div>
                  @if (!system.isSpecificValid('mailDeleteAfterDaysRequired')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.DELETE_MAILS.REQUIRED" | translate }}
                  } @else if (!system.isSpecificValid('mailDeleteAfterDaysMin')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.DELETE_MAILS.MIN" | translate }}
                  } @else if (!system.isSpecificValid('mailDeleteAfterDaysMax')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.DELETE_MAILS.MAX" | translate }}
                  }
                </div>
              </div>
            }
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input numberOnly type="number" class="form-control" id="jobsDeleteAfterDays" name="jobsDeleteAfterDays"
                   [(ngModel)]="system.value.jobsDeleteAfterDays"
                   [ngClass]=" {'pending-changes': system.hasSpecificChanges('jobsDeleteAfterDays') && system.isSpecificValid('jobsDeleteAfterDaysMin', 'jobsDeleteAfterDaysMax', 'jobsDeleteAfterDaysRequired'), 'is-invalid': !system.isSpecificValid('jobsDeleteAfterDaysMin', 'jobsDeleteAfterDaysMax', 'jobsDeleteAfterDaysRequired') }"
                   min="1" max="365">
            <label for="jobsDeleteAfterDays">{{ "SYSTEM.SETTINGS.TIME_TILL_DELETE_FINISHED_JOBS" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="interval"></i>
            </div>
            @if (!system.isSpecificValid('jobsDeleteAfterDaysMin', 'jobsDeleteAfterDaysMax', 'jobsDeleteAfterDaysRequired')) {
              <div class="invalid-feedback">
                <div>
                  @if (!system.isSpecificValid('jobsDeleteAfterDaysRequired')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.DELETE_JOBS.REQUIRED" | translate }}
                  } @else if (!system.isSpecificValid('jobsDeleteAfterDaysMin')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.DELETE_JOBS.MIN" | translate }}
                  } @else if (!system.isSpecificValid('jobsDeleteAfterDaysMax')) {
                    {{ "SYSTEM.SETTINGS.ERRORS.DELETE_JOBS.MAX" | translate }}
                  }
                </div>
              </div>
            }
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="row row-cols-lg-6 g-4">
    <div [classList]="'col-lg-6'">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SETTINGS.SYSTEM_SETTINGS" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3 switch-new-design-black">
            <div id="formDefaultBeeper" class="form-control settings-switch"
                 [ngClass]=" {'pending-changes': system.hasSpecificChanges('defaultBeeper')}">
              <div class="form-check form-switch form-switch-md">
                <input type="checkbox" class="form-check-input" id="defaultBeeper"
                       name="defaultBeeper" [(ngModel)]="system.value.defaultBeeper">
              </div>
            </div>
            <label for="defaultBeeper">{{ "SYSTEM.SETTINGS.SET_DEFAULT_BEEPER_NEW_LOCKS" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="audio"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input numberOnly type="number" class="form-control" id="defaultOpeningDuration"
                   name="defaultOpeningDuration"
                   [(ngModel)]="system.value.defaultOpeningDuration"
                   [ngClass]=" {'pending-changes': system.hasSpecificChanges('defaultOpeningDuration') && system.isSpecificValid('defaultOpeningDurationMin', 'defaultOpeningDurationMax', 'defaultOpeningDurationRequired'), 'is-invalid': !system.isSpecificValid('defaultOpeningDurationMin', 'defaultOpeningDurationMax', 'defaultOpeningDurationRequired') }"
                   min="3" max="180">
            <label
              for="defaultOpeningDuration">{{ "SYSTEM.SETTINGS.SET_DEFAULT_OPEN_DURATION_NEW_LOCKS" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="interval"></i>
            </div>
            @if (!system.isSpecificValid('defaultOpeningDurationMin', 'defaultOpeningDurationMax', 'defaultOpeningDurationRequired')) {
              <div class="invalid-feedback">
                <div>
                  @if (!system.isSpecificValid('defaultOpeningDurationRequired')) {
                    {{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.REQUIRED" | translate }}
                  } @else if (!system.isSpecificValid('defaultOpeningDurationMin')) {
                    {{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.MIN" | translate }}
                  } @else if (!system.isSpecificValid('defaultOpeningDurationMax')) {
                    {{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.MAX" | translate }}
                  }
                </div>
              </div>
            }
          </div>

          <div class="form-floating form-floating-custom mb-3 switch-new-design-black">
            <div id="formLockingDeviceParameterGroups" class="form-control settings-switch"
                 [ngClass]=" {'pending-changes': system.hasSpecificChanges('lockingDeviceParameterGroups')}">
              <div class="form-check form-switch form-switch-md">
                <input type="checkbox" class="form-check-input params-group-switch" id="lockingDeviceParameterGroups"
                       name="lockingDeviceParameterGroups" [ngClass]="{ 'disabled': paramGroupsExist }"
                       [(ngModel)]="system.value.lockingDeviceParameterGroups" [disabled]="paramGroupsExist">
              </div>
            </div>
            <label
              for="lockingDeviceParameterGroups">{{ "SYSTEM.SETTINGS.SET_DISPLAY_PARAMS_GROUPS" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="settings"></i>
            </div>

            <div *ngIf="paramGroupsExist" class="params-group-switch_error invalid-feedback">
              <div><i icon="lock"></i>{{ "SYSTEM.SETTINGS.ERRORS.PARAM_GROUPS_EXISTS" | translate }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div [classList]="'col-lg-6'">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SETTINGS.FIRMWARE" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3 system-firmware">
            <div class="form-floating-icon">
              <i icon="cloud"></i>
            </div>
            <div class="form-label">{{ "SYSTEM.SETTINGS.CYLINDER" | translate }}</div>
            <div class="form-version">{{ firmwareStatusCylinder?.version || "-" }}
              <div *ngIf="firmwareStatusCylinder?.updateRequired" class="firmware-update-required"></div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3 system-firmware">
            <div class="form-floating-icon">
              <i icon="cloud"></i>
            </div>
            <div class="form-label">{{ "SYSTEM.SETTINGS.MOUNT" | translate }}</div>
            <div class="form-version">{{ firmwareStatusMount?.version || "-" }}
              <div *ngIf="firmwareStatusMount?.updateRequired" class="firmware-update-required"></div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3 system-firmware">
            <div class="form-floating-icon">
              <i icon="mdi-lock-open-outline"></i>
            </div>
            <div class="form-label">{{ "SYSTEM.SETTINGS.WALL_TERMINAL" | translate }}</div>
            <div class="form-version">{{ firmwareStatusWallTerminal?.version || "-" }}
              <div *ngIf="firmwareStatusWallTerminal?.updateRequired" class="firmware-update-required"></div>
            </div>
          </div>


          <div class="form-floating form-floating-custom mb-3 black-flex-end form-firmware-sync-container">
            <div class="form-firmware-sync">
              <button type="button" class="m-1 btn btn-outline-secondary" data-testid="item-list-action-buttons"
                      (click)="triggerFirmwareSync($event)"
                      [disabled]="this.firmwareUpdateRunning || newFirmwareAvailable() ? undefined : true">
                <i icon="mdi-lock-open-outline"></i>
                {{ "SYSTEM.SETTINGS.FIRMWARE_SYNC" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
