import {Component, EventEmitter, Input, Output} from '@angular/core';

import {LockingDeviceGroupDetailsDto} from "../../../../../shared/entities/locking-device/LockingDeviceGroupDetailsDto";
import {DEVICE_GROUP} from "../../../../../shared/lookup/locking-device.lookup";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import {
  LockingDeviceGroupFormCdm
} from "../../../../../shared/util/change-detector/models/locking-device-group-form.cdm";

@Component({
    selector: 'app-locking-device-group-form',
    templateUrl: './locking-device-group-form.component.html',
    styleUrls: ['./locking-device-group-form.component.scss'],
    standalone: false
})
export class LockingDeviceGroupFormComponent {
  @Input() accessReadonly: boolean = false;
  lockingDeviceDetails: ChangeDetectorValue = new ChangeDetectorValue(new LockingDeviceGroupFormCdm());

  validatorMaxLength64: number = 64;
  validatorMaxLength32: number = 32;

  constructor() {
  }

  @Input() set lockingDeviceGroup(item: LockingDeviceGroupDetailsDto) {
    if (item == null) {
      return
    }

    let details = new LockingDeviceGroupFormCdm();
    details.id = item.id;
    details.uuid = item.uuid;
    details.name = item.name;
    details.groupTypeId = item.groupTypeId;
    details.description = item.description || '';
    this.lockingDeviceDetails = new ChangeDetectorValue(details, () => {this.onChanges.emit()}, details.validators());
  }

  @Output() onChanges = new EventEmitter<boolean>()

  getLockingDeviceGroupDto(item: LockingDeviceGroupDetailsDto): LockingDeviceGroupDetailsDto {
    // update values
    item.name = this.lockingDeviceDetails.value.name;
    item.description = this.lockingDeviceDetails.value.description;
    item.groupTypeId = this.lockingDeviceDetails.value.groupTypeId;
    return item;
  }

  get deviceGroupTypeTranslationText(): string {
    return `LOCKING_DEVICES.GROUP_TYPE.${[...DEVICE_GROUP.filter(value => (this.lockingDeviceDetails.value.groupTypeId || 1) == value.id), DEVICE_GROUP[0]][0].value.toUpperCase()}`;
  }
}
